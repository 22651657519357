// predefined screen sizes
export const breakpoints = {
	xs: '480px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1600px',
}

// media query utility with mobile-first approach
export const mq = {
    // main utility to target larger devices
	up: function (bp) {
		return `@media (min-width: ${breakpoints[bp]})`
	},
    // special utility to target smaller devices
	down: function (bp) {
		return `@media (max-width: ${breakpoints[bp]})`
	},
}
