import { useNavigate } from "react-router";
import Button from "../../atoms/Button/Button";
import Header from "../../components/Header/Header";
import { SetStateAction, useState } from "react";
import { SIDEBAR_TABS } from "../../constants/sidebar.constants";
import Layout from "../../components/Layout/Layout";
import Sidebar from "../../components/SideBar/Sidebar";
import style from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        fullWidth={true}
        setShowNav={function (value: SetStateAction<boolean>): void {
          throw new Error("Function not implemented.");
        }}
      />
      <Layout
        content={
          <div className={`${style.NotFoundPage} flex-wrap align-center`}>
            <article>
              <strong>404</strong>
              <h1>Page Not Found</h1>
              <p>
                The page you are looking for might be in another universe.
                Please check the URL or click the button below to go back.
              </p>
              <Button label="Go Back" onClick={() => navigate("login")} />
            </article>
          </div>
        }
      />
    </>
  );
};

export default NotFoundPage;
