import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import userReducer from "./userSlice";
import authReducer from "./authSlice"
import userDetailsReducer from './userDetailsSlice'; 

const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    auth: authReducer,
    userDetails: userDetailsReducer,
  },
});

export default store;
