export enum StorageEnums {
  CREDENTIALS = "CREDENTIALS",
  DISCLAIMER_READ = "DISCLAIMER_READ",
}

export enum InsightTypeEnums {
  uncommon_clause = "Uncommon Clause",
  conflict = "Conflict",
  missing = "Missing",
}
