export interface DropdownMenuPorps {
  children: any;
  menuState: any;
}

const DropdownMenu = ({ children, menuState }: DropdownMenuPorps) => {
  const el = document.querySelector("html");
  el?.addEventListener("click", function () {
    menuState(false);
  });

  return <div onClick={(e) => e.stopPropagation()}>{children}</div>;
};
export default DropdownMenu;
