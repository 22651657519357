import { useMutation, useQuery } from "@tanstack/react-query";

export const useDocumentOptionsQuery = () => {
  return useQuery({
    queryKey: ["document-options"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}standard-docs`
      );
      const json = await response.json();
      return json.docs;
    },
  });
};

export const useStandardDocMutation = ({ setNewValue, getText }) => {
  return useMutation({
    mutationKey: ["standard-doc"],
    mutationFn: async (docId) => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}standard-docs/${docId}`
      );
    },
    onSuccess: async (response) => {
      const json = await response.json();
      if ("signed_url" in json) {
        await getText(
          json.signed_url,
          json.file_type,
          (text) => {
            setNewValue(text);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    },
    onError: () => {
      throw new Error("Failed to get Doc url.");
    },
  });
};
