import { useContext, useState, useEffect } from "react";
import { useUploadSafeDocMutation } from "./UserContent.state";
import { Button, Col, Form, Row, Typography } from "antd";
import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { IconAvatar } from "../../../components/components.style";
import {
  ControllerRowItem,
  ControllerRowItemForm,
  DocumentUpload,
  CustomSpace,
} from "../ComparisonForm.style";
import { ComparisonContext } from '../ComparisonProvider'
import { getText } from '../../../utils/textParser'

import Plausible from "plausible-tracker";
const { trackEvent } = Plausible();

const UserContent = () => {
  const { contractStates: [, setUserText] } = useContext(ComparisonContext);
  const [fileList, setFileList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [fileSizeErrors, setFileSizeErrors] = useState(false);
  const { mutateAsync: uploadSafeDoc, isPending } = useUploadSafeDocMutation();

  useEffect(() => {
    getDefaultPDF();
  }, []);

  const getDefaultPDF = async () => {
    const pdfPath = `${process.env.REACT_APP_FRONTEND_URL}your_contract_sample.pdf`;
   const response = await fetch(pdfPath)
   const file = await response.blob();
   await loadPDFtoDiffView(file);
  }

  const loadPDFtoDiffView = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      await getText(
        reader.result,
        file.type,
        (text) => {
          setUserText(text);
        },
        (error) => {
          console.log("error", error);
        }
      );
    };
  }

  const getUserDocUrl = async () => {
    setProcessing(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    const file = fileList[0];
    if (file === undefined) {
      return;
    }

    trackEvent('safeUpload', { props: { fileName: file.fileName } });

    await loadPDFtoDiffView(file);
    // Compute file hash and prepare to send file to s3
    await uploadSafeDoc(file);
    setTimeout(() => {
      // manually set processing to false after 3 seconds
      // since DiffViewer is not broadcasting the end of the process
      setProcessing(false);
    }, 3000);
  };

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileSizeErrors(false);
    },
    beforeUpload: (file) => {
      var fileSizeInMB = file.size / 1024 / 1024;
      // Setting upload size limit to (arbitrary) 2 MB
      if (fileSizeInMB < 2) {
        setFileSizeErrors(false);
      } else {
        trackEvent('bigUploadError', { props: { fileName: file.fileName, fileSize: file.size } });
        file.status = 'error';
        file.response = 'File is too large (>2MB)'
        setFileSizeErrors(true);
      }
      setFileList([file]);
      return false;
    },
    fileList,
    accept: ".docx,.pdf",
    maxCount: 1,
  };

  return (
    <form style={{ height: '100%' }}>
      <ControllerRowItem>
        <IconAvatar shape="square">
          <PlusOutlined />
        </IconAvatar>
        <ControllerRowItemForm>
          <Form.Item>
            <Typography.Title level={4}>2. Upload Your Contract</Typography.Title>
            <CustomSpace />
            <Typography.Text>No Contract?  <a href='https://docs.google.com/document/d/1EcN-K1IhGLmf4hCfq4x3XqDudOtGD_0f/edit?usp=sharing&ouid=117980480935820481309&rtpof=true&sd=true' target="_blank" rel="noopener noreferrer">Load our Sample Contract</a> Instead!</Typography.Text>
          </Form.Item>

          <Row gutter={12}>
            <Col md={18}>
              <Form.Item
                name="filename"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e?.fileList;
                }}
              >
                <DocumentUpload {...uploadProps}>
                  <Button block icon={<CloudUploadOutlined />}>
                    Upload File
                  </Button>
                </DocumentUpload>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Button
                disabled={isPending || processing || fileList.length === 0 || (fileList.length > 0 && fileSizeErrors)}
                loading={isPending || processing}
                type="primary"
                onClick={getUserDocUrl}
                block
              >
                Submit
              </Button>
            </Col>
          </Row>
        </ControllerRowItemForm>
      </ControllerRowItem>
    </form>
  );
};

export default UserContent;
