import React from "react";
import style from "./RadioBox.module.scss";

export interface RadioBoxProps {
  label?: string;
  register?: any;
  registerName?: string;
  value: string;
  children?: any;
  error?: boolean;
}

const RadioBox = ({
  label,
  register,
  registerName,
  value,
  children,
  error = false,
}: RadioBoxProps) => {
  return (
    <label className={`${style.checkBox} ${error && style.isError}`}>
      <input
        type="radio"
        name={registerName}
        value={value}
        {...(register ? register(registerName) : {})}
      />

      <span>
        <cite></cite>
        {children ? children : label}
      </span>
    </label>
  );
};

export default RadioBox;
