import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class UserService {
  getUserProfile(token: string) {
    const obj = {
      url: "users/me",
      headerToken: token ,
    };
    return apiService.get(obj);
  }
}
