import Input from "../../atoms/Input/Input";
import LogoIcon from "../../assets/images/logo-icon.png";
import UserIcon from "../../assets/images/user.svg";

const Step1 = ({
  style,
  errors,
  register,
  formValue,
}: {
  style: any;
  errors: any;
  register: any;
  formValue: any;
}) => {
  return (
    <div className={style.signUp__block}>
      <div className={style.signUp__header}>
        <img src={LogoIcon} alt="B" />
        <strong>Personal Details</strong>
        <span>Please provide your name, email and password</span>
      </div>

      <Input
        type="text"
        placeholder="Full Name"
        register={register}
        registerName="fullName"
        prefixIcon={UserIcon}
        errorMessage={errors?.fullName?.message}
        value={formValue.fullName}
      />
      <Input
        type="email"
        placeholder="Email"
        register={register}
        registerName="email"
        errorMessage={errors?.email?.message}
        value={formValue.email}
      />
      <Input
        type="password"
        placeholder="Password"
        register={register}
        registerName="password"
        marginBottom={false}
        errorMessage={errors?.password?.message}
        value={formValue.password}
      />
    </div>
  );
};

export default Step1;
