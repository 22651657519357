import style from "./Highlights.module.scss";
import InfoIcon from "../../../assets/images/info.svg";
import QuestionIcon from "../../../assets/images/question.svg";
import { useEffect, useState } from "react";
import { StorageEnums } from "../../../enums/storageEnums";
import { ApiService } from "../../../services/apiServices";
import { HighlightsTypeEnum } from "../../../constants/enums/enums";
import RedShield from "../../../assets/images/red-shield.svg";
import RedCheck from "../../../assets/images/red-check.svg";
import BlueShield from "../../../assets/images/blue-shield.svg";
import CommonClasueIcon from "../../../assets/images/common-clause-icon.svg";
import UncommonClauseIcon from "../../../assets/images/uncommon-clause-icon.svg";
import UnclearContentIcon from "../../../assets/images/unclear-content.svg";
import arrowDown from "../../../assets/images/arrow-down-2.svg"


export interface HighlightsType {
  id: string;
  name: string;
  dash_icon: string;
  doc_icon: string;
}

interface SafeInsightTypes {
  id: string;
  type_name: string;
  type_terms: string;
}

const Highlights = ({ contracts }: any) => {
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );
  const [highlightTypes, setHighlightTypes] = useState<HighlightsType[]>([]);
  const [safeInsightTypes, setSafeInsightTypes] = useState<SafeInsightTypes[]>([]);

  useEffect(() => {
    const fetchHighlightTypes = async () => {
      try {
        const obj = {
          url: `highlight-types`,
          headerToken: `${localStorageUser.accessToken.jwtToken}`,
        };
        const response = await new ApiService().get(obj);
        setHighlightTypes(response.data.types);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSafeInsightTypes = async () => {
      try {
        const response = await new ApiService().get({
          url: 'safe-insight-types'
        })
        setSafeInsightTypes(JSON.parse(response.data.body).types)
      } catch (err) {
        console.log("err fetching safe insight types")
      }
    }
    fetchHighlightTypes();
    fetchSafeInsightTypes();
  }, []);

  const getHighlightName = (type: string) => {
    const highlightType = highlightTypes.find((ht) => ht.id === type);
    return highlightType ? highlightType.name : "-";
  };

  const isConflicted = (type: string) => {
    const highlightType: any = highlightTypes.find((ht) => ht.id === type);
    if (
      highlightType?.name === HighlightsTypeEnum.CONFLICT ||
      highlightType?.name === HighlightsTypeEnum.UNCOMMON_CLAUSE
    ) {
      return true;
    } else {
      return false;
    }
  };

  const findSafeInsightType = (id: string) => {
    return safeInsightTypes.find((each) => each.id === id)
  };

  const findSafeInsight = (id: string) => {
    if (contracts) {
      const found = contracts?.insights?.safe?.find((each: any) => each["safe_insight_types_id"] === id);
      return found;
    }
  }

  const highlights = [
    {
      type: "Board Observer Seat",
      safe_insight_type_id: "BOARD_OBSERVER_ID",
      icon: RedShield,
      highlight_type: "Common Clause",
    },
    {
      type: "Board Seat",
      safe_insight_type_id: "",
      icon: RedCheck,
      highlight_type: "Uncommon Clause",
    },
    {
      type: "Most Favored Nations(MFN)",
      safe_insight_type_id: "MFN_ID",
      icon: BlueShield,
      highlight_type: "Common Clause",
    },
    {
      type: "Pro Rata Rights",
      safe_insight_type_id: "",
      icon: BlueShield,
      highlight_type: "Unclear Content",
    },
    {
      type: "Information Rights",
      safe_insight_type_id: "",
      icon: BlueShield,
      highlight_type: "Unclear Content",
    },
  ];

  const transformString = (input: string) => {
    const prefix = "Yes, ";
    if (input.startsWith(prefix)) {
      input = input.slice(prefix.length);
    }
    input = input.charAt(0).toUpperCase() + input.slice(1);
    return input;
  }

  const highlightMap = highlights.map((each, index) => {
    const safeInsight = findSafeInsight(each.safe_insight_type_id);
    const safeInsightType = findSafeInsightType(each.safe_insight_type_id);
    const requested = safeInsight?.insight_value.includes('Yes');
    const type_name = safeInsightType?.type_name;
    const type_terms = safeInsightType?.type_terms;
    switch (index) {
      case 0:
        return {
          ...each,
          requested,
          type_name,
          type_terms,
        }
      case 1:
        return {
          ...each,
          requested: false,
          type_name: "Board Seat Terms",
          type_terms: "Board Seat has not been requested in this document., which is standard.  According to the Angel Capital Association, only 4% of SAFEs awarded board seats in 2022, typically for investors with $600k invested on average.",
        }
      case 2:
        return {
          ...each,
          requested,
          type_name,
          type_terms: requested ? transformString(safeInsight.insight_value) : type_terms,
        }
      case 3:
        return {
          ...each,
          requested: true,
          type_name: "Pro Rata Rights Terms",
          type_terms: "This pro rata clause is standard in investment agreements. However, the exact termination conditions of this clause is missing in the SAFE agreement.",
        }
      case 4:
        return {
          ...each,
          requested: true,
          type_name: "Information Rights Terms",
          type_terms: "These information rights are common in investment agreements. They allow investors to stay informed about the company’s performance and operations without giving access to sensitive information. This balance helps investors monitor their investment while protecting the company's critical data. However, the scope and duration of information rights are missing from this agreement."
        }
    }
  })

  return (
    <div className={style.important}>
      <h3>Important Highlights</h3>
      {highlightMap.map((each, idx) => {
        const color = each?.highlight_type === "Common Clause" ? "green" : each?.highlight_type === "Uncommon Clause" ? "red" : "blue";
        return (
          <div className={`${style.important__row} flex-wrap align-start`} key={idx}>
            <div className={style.important__left}>
              <div className="flex align-center">
                <img src={each?.icon} />
                <h4>{each?.type}</h4>
              </div>
              <p>{each?.requested ? "Requested" : "Not Requested"}</p>
            </div>
            <div className={`${style.important__middle} ${style[color]}`}>
              {each?.highlight_type === "Common Clause" && <img src={CommonClasueIcon} alt="icon" />}
              {each?.highlight_type === "Uncommon Clause" && <img src={UncommonClauseIcon} alt="icon" />}
              {each?.highlight_type === "Unclear Content" && <img src={UnclearContentIcon} alt="icon" />}
              <p>{each?.highlight_type}</p>
            </div>
            <div className={style.important__right}>
              <h5>{each?.type_name}</h5>
              <p>{each?.type_terms}</p>
            </div>
          </div>
        )
      })}
      <div className={style.important__viewmore}>
        View 2 More
        <img src={arrowDown} alt="expand" />
      </div>
    </div>
  );
};

export default Highlights;
