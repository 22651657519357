import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {
  getDocument,
  GlobalWorkerOptions,
} from "pdfjs-dist/legacy/build/pdf.js";

const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.js");
GlobalWorkerOptions.workerSrc = pdfjsWorker;

export async function getText(url, fileType, successCallback, errorCallback) {
  switch (fileType) {
    case "docx":
      await getTextDocx(url, true, successCallback, errorCallback);
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      await getTextDocx(url, true, successCallback, errorCallback);
      break;
    case "pdf":
    case "application/pdf":
      await getTextPdf(url, successCallback, errorCallback);
      break;
    default:
      console.log("Unsupported file type");
  }
}

export async function getTextDocx(
  url,
  compressed,
  successCallback,
  errorCallback
) {
  PizZipUtils.getBinaryContent(url, function (error, content) {
    if (error) {
      // throw error;
      errorCallback(error);
    }
    var file = content;
    if (compressed) {
      file = new PizZip(content);
    }
    var doc = new Docxtemplater(file);
    var text = doc.getFullText();
    successCallback(text);
  });
}

export async function getTextPdf(url, successCallback, errorCallback) {
  await getDocument(url)
    .promise.then((pdfDocument) => {
      var numPages = pdfDocument.numPages;
      var promises = [];
      for (var pageNum = 0; pageNum < numPages; pageNum++) {
        promises.push(
          pdfDocument
            .getPage(pageNum + 1)
            .then((page) => {
              return page.getTextContent();
            })
            .then((text) => {
              let pageText = "";
              for (var line of text.items) {
                var token = line["str"];
                if (token === "") {
                  token = " ";
                }
                pageText += token;
              }
              return pageText;
            })
            .catch((error) => {
              errorCallback(error);
            })
        );
      }
      return promises;
    })
    .then((promises) => {
      Promise.all(promises).then((results) => {
        var fullText = results.join(" ");
        successCallback(fullText);
      });
    })
    .catch((error) => {
      errorCallback(error);
    });
}
