import * as yup from "yup";

export const signUpSchema = yup.object().shape({
  fullName: yup.string().required("Name is required").trim(),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, a number, a special character, a lowercase character, and an uppercase character"
    ),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is required")
    .trim()
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Please enter a valid email."
    ),
  businessName: yup.string().required("Business name is required").trim(),
  city: yup.string().required("City is required").trim(),
  state: yup.string().required("Please select an option"),
  fundraisingStage: yup.string().required("Please select an option"),
  industry: yup.string().required("Industry is required").trim(),
  yearlyRevenue: yup.string().required("Please select an option"),
  agreeToTerms: yup.boolean().oneOf([true]),
  frequencyOfSigningContracts: yup.string().required("Please select an option"),
  safesContracts:
    yup.number()
      .transform((value, originalValue) => {
        return (originalValue === '' || originalValue === null) ? 0 : value;
      })
      .min(0, 'Must be a number greater than or equal to 0')
      .typeError('Must be a number greater than or equal to 0')
  ,
  serviceAgreementsContracts: yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' || originalValue === null) ? 0 : value;
    })
    .min(0, 'Must be a number greater than or equal to 0')
    .typeError('Must be a number greater than or equal to 0')
  ,
  ndasContracts: yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' || originalValue === null) ? 0 : value;
    })
    .min(0, 'Must be a number greater than or equal to 0')
    .typeError('Must be a number greater than or equal to 0')
  ,
  workContracts: yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' || originalValue === null) ? 0 : value;
    })
    .min(0, 'Must be a number greater than or equal to 0')
    .typeError('Must be a number greater than or equal to 0')
  ,
  procurementContracts: yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' || originalValue === null) ? 0 : value;
    })
    .min(0, 'Must be a number greater than or equal to 0')
    .typeError('Must be a number greater than or equal to 0')
  ,
  otherContracts: yup.string().nullable().trim(),
  numberOfOtherContracts: yup.number()
    .transform((value, originalValue) => {
      return (originalValue === '' || originalValue === null) ? 0 : value;
    })
    .min(0, 'Must be a number greater than or equal to 0')
    .typeError('Must be a number greater than or equal to 0')
  ,
});
