import React from "react";
import style from "./Layout.module.scss";

export interface LayoutProps {
  sidebar?: any;
  content: any;
}

const Layout = ({ sidebar, content }: LayoutProps) => {
  return (
    <main className={`${style.layout} ${!sidebar && style.isFull} flex-wrap`}>
      {sidebar}
      <aside className={style.layout__content}>{content}</aside>
    </main>
  );
};
export default Layout;
