import React from "react";
import style from "./CheckBox.module.scss";
export interface CheckBoxProps {
  label?: string;
  register?: any;
  registerName?: string;
  children?: any;
  error?: boolean;
}

const CheckBox = ({
  label,
  register,
  registerName,
  children,
  error = false,
}: CheckBoxProps) => {
  return (
    <label className={`${style.checkBox} ${error && style.isError}`}>
      <input
        type="checkbox"
        name={registerName}
        {...(register ? register(registerName) : {})}
      />
      <span>{children ? children : label}</span>
    </label>
  );
};
export default CheckBox;
