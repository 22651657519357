import { useEffect, useState } from "react";
import HorizontalTabBar from "../../../components/HorizontalTabBar/HorizontalTabBar";
import { Outlet, useNavigate, useParams } from "react-router";
import { StorageEnums } from "../../../enums/storageEnums";
import { ApiService } from "../../../services/apiServices";
import Skeleton from "../../../atoms/Skeleton/Skeleton";
import { shortenName } from "../../../constants/constants";
import GlobalLoader from "../../../components/GlobalLodaer/GlobalLoader";

const Contracts = () => {
  const navigate = useNavigate();
  const { id, docId } = useParams();
  const [contractData, setContractData] = useState<any>();
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );
  const [updateDocument, setUpdateDocument] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (id && contractData?.length && !updateDocument) {
      navigate(`${contractData[0]?.value}`, { replace: true });
    }
  }, [contractData, id]);

  useEffect(() => {
    const handleDocumentUpdated = () => {
      setUpdateDocument(true);
      getDocsTabs();
    };

    window.addEventListener("documentUpdated", handleDocumentUpdated);

    return () => {
      window.removeEventListener("documentUpdated", handleDocumentUpdated);
    };
  }, []);

  async function getDocsTabs() {
    try {
      setLoading(true);
      const obj = {
        url: `contracts/${id}`,
        headerToken: `${localStorageUser?.accessToken?.jwtToken}`,
      };
      let contracts = await new ApiService().get(obj);

      let data = contracts?.data?.documents.map((el: any) => {
        return {
          id: el.id,
          name: shortenName(el?.name).split(".")[0],
          value: el.id.toString(),
        };
      });
      setContractData([
        // TODO: These lines control the summary view. Uncomment them to re-enable that view
        // {
        //   id: -1,
        //   name: contracts?.data?.name,
        //   value: "summary",
        // },
        ...data,
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDocsTabs();
  }, []);

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          <HorizontalTabBar tabs={contractData} />
          <Outlet />
        </>
      )}
    </>
  );
};

export default Contracts;
