import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import AuthGuard from "../guards/Auth.guard";
import UnAuthGuard from "../guards/UnAuth.guard";
import AdminGuard from "../guards/Admin.guard";
import ConfirmSignup from "../pages/ConfirmPassword/ConfirmPassword";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SignUp from "../pages/SignUp/SignUp";
import UserDashboard from "../pages/UserDashboard/UserDashboard";
import Home from "../pages/Home/Home";
import HelpResources from "../pages/HelpResources/HelpResources";
import MessageCenter from "../pages/MessageCenter/MessageCenter";
import Documents from "../pages/Contracts/Documents/Documents";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import Contracts from "../pages/Contracts/Contracts/Contracts";
import ComparisonForm from "../pages/ComparisonForm";
import Admin from "../pages/Admin/Admin";

const RouterPath = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="login" replace={true} />,
    },
    {
      path: "/login",
      element: (
        <UnAuthGuard>
          <Login />
        </UnAuthGuard>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <UnAuthGuard>
          <ForgotPassword />
        </UnAuthGuard>
      ),
    },
    {
      path: "/reset-password/:email",
      element: (
        <UnAuthGuard>
          <ForgotPassword />
        </UnAuthGuard>
      ),
    },
    {
      path: "/signup",
      element: (
        <UnAuthGuard>
          <SignUp />
        </UnAuthGuard>
      ),
    },
    {
      path: "confirm-email/:email",
      element: (
        <UnAuthGuard>
          <ConfirmSignup />
        </UnAuthGuard>
      ),
    },
    {
      path: "confirm-password/:email",
      element: (
        <UnAuthGuard>
          <ResetPassword />
        </UnAuthGuard>
      ),
    },
    {
      path: "admin",
      element: (
        <AdminGuard>
          <Admin />
        </AdminGuard>
      )
    },
    {
      path: "safedemo",
      element: (
        // <UnAuthGuard>
        <ComparisonForm />
        // </UnAuthGuard>
      ),
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <UserDashboard />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "contracts/:id",

          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Navigate to="docs" replace={true} />,
            },
            {
              path: "docs",
              element: <Contracts />,

              children: [
                {
                  path: ":docId",
                  element: <Documents />,
                },
              ],
            },
          ],
        },
        {
          path: "help-resources",
          element: <HelpResources />,
        },
        {
          path: "message-center",
          element: <MessageCenter />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default RouterPath;
