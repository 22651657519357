import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { ApiDataObject } from "../interfaces/interface";
import { ApiService } from "./apiServices";

export const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
  ClientId: process.env.REACT_APP_CLIENT_ID ?? "",
};

export class CognitoService {
  userPool = new CognitoUserPool(poolData);
  apiService = new ApiService();

  cognitoSignUp({
    email,
    password,
    fullName,
    businessName,
    city,
    state,
    fundraisingStage,
    industry,
    yearlyRevenue,
    frequencyOfSigningContracts,
    safesContracts,
    workContracts,
    serviceAgreementsContracts,
    ndasContracts,
    procurementContracts,
    otherContracts,
    numberOfOtherContracts

  }: {
    email: string,
    password: string,
    fullName: string,
    businessName: string,
    city: string,
    state: string,
    fundraisingStage: string,
    industry: string,
    yearlyRevenue: string,
    frequencyOfSigningContracts: string,
    safesContracts: string,
    serviceAgreementsContracts: string,
    ndasContracts: string,
    workContracts: string,
    procurementContracts: string,
    otherContracts: string,
    numberOfOtherContracts: string

  }
  ) {
    return new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: "email",
          Value: email || '',
        }),
        new CognitoUserAttribute({
          Name: "name",
          Value: fullName || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:businessName",
          Value: businessName || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:city",
          Value: city || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:state",
          Value: state || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:fundraisingStage",
          Value: fundraisingStage || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:industry",
          Value: industry || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:yearlyRevenue",
          Value: yearlyRevenue || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:freqSignCtrs",
          Value: frequencyOfSigningContracts || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:safesCtrcts",
          Value: safesContracts.toString() || '0',
        }),
        new CognitoUserAttribute({
          Name: "custom:svcAgrCtrcts",
          Value: serviceAgreementsContracts.toString() || '0',
        }),
        new CognitoUserAttribute({
          Name: "custom:ndasContracts",
          Value: ndasContracts.toString() || '0',
        }),
        new CognitoUserAttribute({
          Name: "custom:procureCtrcts",
          Value: procurementContracts.toString() || '0',
        }),
        new CognitoUserAttribute({
          Name: "custom:workContracts",
          Value: workContracts.toString() || '0',
        }),
        new CognitoUserAttribute({
          Name: "custom:othrCtrcts",
          Value: otherContracts || '',
        }),
        new CognitoUserAttribute({
          Name: "custom:numOthCtrct",
          Value: numberOfOtherContracts.toString() || '0',
        }),
      ];



      this.userPool.signUp(
        email,
        password,
        attributeList,
        [],
        function (error: any, result: any) {
          if (error != null) {
            reject(error);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  cognitoConfirmSignup(confirmCode: string, email: string) {
    return new Promise((resolve, reject) => {
      let cognito = new CognitoUser({ Username: email, Pool: this.userPool });

      cognito.confirmRegistration(
        confirmCode,
        true,
        function (error: any, result: any) {
          if (error !== null) {
            reject(error);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  resendConfirmationCode(email: string) {
    let cognito = new CognitoUser({ Username: email, Pool: this.userPool });
    return new Promise<any>((resolve, reject) => {
      cognito.resendConfirmationCode(function (err: any, result: any) {
        if (err !== null) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  cognitoLogin(email: string, password: string) {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      let cognito = new CognitoUser({ Username: email, Pool: this.userPool });
      cognito.authenticateUser(authenticationDetails, {
        onSuccess: async (result: CognitoUserSession) => {
          resolve(result);
        },
        onFailure: (error: any) => {
          reject(error);
        },
      });
    });
  }

  CognitoLogout = () => {
    const user = this.userPool.getCurrentUser();
    if (user != null) {
      user.signOut();
    }
  };

  refreshSession(email: string, refreshToken: string) {
    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.refreshSession(
        new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        (error, session) => {
          if (error != null) {
            reject(error);
          }
          resolve(session);
        }
      );
    });
  }

  forgotPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });

      user.forgotPassword({
        onSuccess: function (data) {
          resolve(data);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  confirmPassword = (email: string, code: string, password: string) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });
      cognitoUser.confirmPassword(code, password, {
        onFailure(err) {
          reject(err);
        },
        onSuccess(res) {
          resolve(res);
        },
      });
    });
  };

  createUser(email: string, name: string, cognitoId: string) {
    const obj: ApiDataObject = {
      url: "user",
      data: {
        email,
        name,
        cognitoId,
      },
    };
    return this.apiService.post(obj);
  }

  async updateUser(userId: string) {
    return new Promise(async (res, rej) => {
      const user = this.getCurrentUser();
      const customAttribute = new CognitoUserAttribute({
        Name: "custom:user_id",
        Value: userId,
      });
      if (!user) {
        rej();
      }
      await new Promise((res) => user?.getSession(res));
      const properties = await this.getUserAttributes(user);
      const found = properties.find((e) => e.Name === customAttribute.Name);
      if (!found) {
        res(true);
      }
      user?.updateAttributes([customAttribute], (err, results, details) => {
        if (err) {
          rej(err);
        }
        res(results);
      });
    });
  }

  async getUserAttributes(
    user: CognitoUser | null
  ): Promise<CognitoUserAttribute[]> {
    return new Promise((res, rej) => {
      user?.getUserAttributes((err: any, properties: any) => {
        if (err) {
          rej(err);
        }
        res(properties);
      });
    });
  }
}
