import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import authImg from "../../assets/images/auth-image.png";
import LogoIcon from "../../assets/images/logo-icon.png";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import Logo from "../../atoms/Logo/Logo";
import { CognitoService } from "../../services/auth.service";
import { resetPasswordSchema } from "./resetPassword.schema";
import LockIcon from "../../assets/images/lock.svg";

const ResetPassword = () => {
  const service = new CognitoService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { email = "" } = useParams();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onChange",
  });

  const formValue = watch();

  const handleResetPassword = async (data: any) => {
    try {
      setLoading(true);
      await service.confirmPassword(email, data.code, data.password);
      setLoading(false);
      toast.success("Password has been updated successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      })
      navigate("/login");
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      setLoading(true);
      const res = await service.forgotPassword(email);
      toast.success("A verification code has been resent.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <section className={`${style.auth} flex-wrap`}>
        <aside className={style.auth__content}>
          <Logo />
          <div className={style.auth__block}>
            <div className={style.auth__header}>
              <img src={LogoIcon} alt="B" />
              <strong>Reset password</strong>
              <span>A verification code has been sent to your email address.</span>
            </div>

            <form onSubmit={handleSubmit(handleResetPassword)} noValidate>
              <Input
                type="text"
                label="Code"
                placeholder="Code"
                register={register}
                registerName="code"
                value={formValue.code}
                errorMessage={errors?.code?.message}
                prefixIcon={LockIcon}
              />
              <Input
                type="password"
                label="Password"
                placeholder="Password"
                register={register}
                registerName="password"
                value={formValue.password}
                errorMessage={errors?.password?.message}
              />

              <Button
                type="submit"
                fullWidth={true}
                loading={loading}
                disabled={loading}
                label={"Reset Password"}
              />
              <div className={`${style.auth__resend} flex justify-center`}>
                <a onClick={resendCode}>Resend code</a>
              </div>
            </form>
          </div>

          <div className={style.auth__alignment}></div>
        </aside>
        <aside className={`${style.auth__image} flex align-end`}>
          <img src={authImg} alt="Image" />
        </aside>
      </section>
    </>
  );
};

export default ResetPassword;
