import React, { useState } from "react";
import eyeClose from "../../assets/images/eye-close.svg";
import eyeOpen from "../../assets/images/eye-open.svg";
import { CustomInputProps } from "../../interfaces/interface";
import AtIcon from "../../assets/images/at.svg";
import LockIcon from "../../assets/images/lock.svg";
import style from "./Input.module.scss";

const Input: React.FC<CustomInputProps> = ({
  label,
  type,
  value,
  placeholder,
  errorMessage = "",
  register,
  registerName,
  disabled = false,
  min = "",
  marginBottom = true,
  readOnly = false,
  prefixIcon = "",
}: CustomInputProps) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "number" && (e.key === "e" || e.key === "E")) {
      e.preventDefault();
    }
  };

  const handleFocus = (e: any) => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div
      className={`${style.inputField} ${type === "password" && style.inputPassword
        } ${!marginBottom && style.noSpacing} ${!prefixIcon && type !== "email" && type !== "password"
          ? style.noPrefix
          : ""
        }`}
    >
      <div
       className={`${style.inputField__label} ${(prefixIcon || type === "email"|| type === "password")&& style.withIcon} ${(isFocused || value) && style.inputField__labelFocused}`}
      >
        {placeholder}
      </div>
      <div className={style.inputField__inner}>
        {type === "textArea" ? (
          <textarea
            // placeholder={isFocused ? "" : placeholder}
            value={value}
            name={registerName}
            {...register(registerName)}
            rows="4"
            cols="83"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        ) : (
          <>
            <input
              type={type === "password" && isPasswordVisible ? "text" : type}
              // placeholder={isFocused ? "" : placeholder}
              value={value}
              name={registerName}
              disabled={disabled}
              onKeyPress={handleKeyPress}
              {...register(registerName)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              min={min}
              readOnly={readOnly}
            />
            {!prefixIcon && type === "email" && (
              <div className={`${style.inputField__icon}`}>
                <img src={AtIcon} alt="@" />
              </div>
            )}
            {!prefixIcon && type === "password" && (
              <div className={`${style.inputField__icon}`}>
                <img src={LockIcon} alt="Lock" />
              </div>
            )}
            {prefixIcon && (
              <div className={`${style.inputField__icon}`}>
                <img src={prefixIcon} alt="icon" />
              </div>
            )}
            {type === "password" && (
              <div className={`${style.inputField__visibility}`}>
                <img
                  onMouseDown={(e) => e.preventDefault()}
                  src={isPasswordVisible ? eyeOpen : eyeClose}
                  alt="eye"
                  onClick={togglePasswordVisibility}
                />
              </div>
            )}
          </>
        )}
      </div>
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  );
};

export default Input;
