import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import authImg from "../../assets/images/auth-image.png";
import LogoIcon from "../../assets/images/logo-icon.png";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import Logo from "../../atoms/Logo/Logo";
import { StorageEnums } from "../../enums/storageEnums";
import { CognitoService } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import { logInSchema } from "./login.schema";
import { toast } from "react-toastify";

const Login = () => {
  const service = new CognitoService();
  const storageService = new StorageService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({
    resolver: yupResolver(logInSchema),
    mode: "onChange",
  });
  const formValue = watch();
  const handleLogin = async (data: any) => {
    try {
      setLoading(true);
      const res = await service.cognitoLogin(data.email, data.password);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
      navigate("/dashboard");
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (error.name === "UserNotConfirmedException") {
        navigate(`/confirm-email/${data.email}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className={`${style.auth} flex-wrap`}>
        <aside className={style.auth__content}>
          <Logo />
          <div className={style.auth__block}>
            <div className={style.auth__header}>
              <img src={LogoIcon} alt="B" />
              <strong>Welcome Back</strong>
              <span>Enter your Brief account details</span>
            </div>

            <form onSubmit={handleSubmit(handleLogin)} noValidate>
              <Input
                type="email"
                label="Email address"
                placeholder="Email address"
                register={register}
                registerName="email"
                value={formValue.email}
                errorMessage={errors?.email?.message}
              />
              <Input
                type="password"
                label="Password"
                placeholder="Password"
                register={register}
                registerName="password"
                marginBottom={false}
                value={formValue.password}
                errorMessage={errors?.password?.message}
              />
              <div
                className={`${style.auth__forgot} flex justify-end`}
                onClick={() => navigate("/forgot-password")}
              >
                <a>Forgot Password?</a>
              </div>

              <Button
                type="submit"
                fullWidth={true}
                loading={loading}
                disabled={loading}
                label={"SIGN IN"}
              />
            </form>
            <div className={style.auth__create}>
              Don't have an account?
              <span onClick={() => navigate("/signup")}> Sign Up</span>
            </div>
          </div>

          <div className={style.auth__alignment}></div>
        </aside>
        <aside className={`${style.auth__image} flex align-end`}>
          <img src={authImg} alt="Image" />
        </aside>
      </section>
    </>
  );
};

export default Login;
