import { Avatar, Typography } from 'antd'
import { UserContainer, UserInfo } from './UserSection.style'

const UserSection = () => {
    return (
        <UserContainer>
            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            <UserInfo>
                <Typography.Text strong> Brief Demo</Typography.Text>
                <Typography.Text>Account Settings</Typography.Text>
            </UserInfo>
        </UserContainer>
    )
}

export default UserSection