import React from "react";
import {
  BellOutlined,
  HomeOutlined,
  MenuOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Layout, Typography } from "antd";
import { HeaderSection, HeaderTools, LayoutBody, LayoutContent, LayoutFooter, LayoutHeader, LayoutMenu, LayoutMenuContainer, LayoutSider, LayoutSiderAction, LogoContainer } from './GeneralLayout.style'
import LogoBrief from '../assets/images/logo-brief.png'
import UserSection from './UserSection'

import Plausible from "plausible-tracker";
const { trackEvent } = Plausible();

const items = [{
  key: '/',
  icon: <HomeOutlined />,
  label: `Home`,
}];

const GeneralLayout = (props) => {
  const { children } = props;
  const waitlistClick = () => {
    trackEvent('waitlistClicked');
    window.location.href = "https://briefme.io/request-demo";
  }

  const mailchimpFormHTML = {
    __html: `
    <div id="mc_embed_signup">
      <form action="https://briefme.us21.list-manage.com/subscribe/post?u=c7d7c392c0c59a0bf57f0ba1d&amp;id=95892f5576&amp;f_id=00d4f3e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group">
            <label class='label' for="mce-FNAME">Name</label>
            <input type="text" name="FNAME" class="required" id="mce-FNAME" required />
          </div>
          <div class="mc-field-group">
            <label class='label' for="mce-EMAIL">Email Address</label>
            <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required />
          </div>
          <div class="clear">
            <input type="submit" value="Join our Waitlist" name="Join our Waitlist" id="mc-embedded-subscribe" class="button" />
          </div>
        </div>
      </form>
    </div>
    `

  }

  return (
    <Layout>
      <LayoutSider
        breakpoint="lg"
        collapsedWidth="0"
      >
        <LogoContainer>
          <a href="/"><img src={LogoBrief} alt="Brief" /></a>
          <MenuOutlined />
        </LogoContainer>
        <LayoutMenuContainer>
          <LayoutMenu
            mode="inline"
            defaultSelectedKeys={["/"]}
            items={items}
          />
        </LayoutMenuContainer>
        <LayoutSiderAction>
          <div dangerouslySetInnerHTML={mailchimpFormHTML} />
        </LayoutSiderAction>
      </LayoutSider>
      <Layout>
        <LayoutHeader>
          <LayoutBody>
            <HeaderSection>
              <Typography.Title>SAFE Comparison Tool</Typography.Title>
              <HeaderTools>
                <SettingOutlined />
                <BellOutlined />
                <UserSection />
              </HeaderTools>
            </HeaderSection>
          </LayoutBody>
        </LayoutHeader>
        <LayoutContent>
          <LayoutBody>
            {children}
          </LayoutBody>
        </LayoutContent>
        <LayoutFooter>
          <LayoutBody>
            Copyright &copy; Briefme, Inc. | Brief is not an attorney or a law firm, and can only provide self-help services at your specific direction.
          </LayoutBody>
        </LayoutFooter>
      </Layout>
    </Layout>
  );
};

export default GeneralLayout;
