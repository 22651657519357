import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "../../atoms/Button/Button";
import Logo from "../../atoms/Logo/Logo";
import { CognitoService } from "../../services/auth.service";
import style from "./SignUp.module.scss";
import SignUpLeft from "./SignUpLeft";
import { signUpSchema } from "./signUp.schema";
import Step1 from "./Step1";
import Step2 from "./Step2";

const service = new CognitoService();
const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const methods = useForm({
    resolver: yupResolver(signUpSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    control,
    watch,
  } = methods;

  const formValue = watch();

  const checkStep1Valid = async () => {
    const isValid = await trigger(["fullName", "email", "password"]);
    return isValid;
  };

  const handleContinue = async () => {
    const isValid = await checkStep1Valid();
    if (isValid) setStep(2);
  };

  const handleSignUp = async (data: any) => {
    const dataToUpload = {
      ...data,
      safesContracts: data?.safesContracts.toString() || 0,
      serviceAgreementsContracts:
        data?.serviceAgreementsContracts.toString() || 0,
      ndasContracts: data?.ndasContracts.toString() || 0,
      procurementContracts: data?.procurementContracts.toString() || 0,
      numberOfOtherContracts: data?.numberOfOtherContracts.toString() || 0,
      workContracts: data?.workContracts.toString() || 0,
    };
    try {
      setLoading(true);
      const res: any = await service.cognitoSignUp(dataToUpload);
      navigate(`/confirm-email/${data.email}`);
    } catch (error: any) {
      console.error(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            style={style}
            errors={errors}
            register={register}
            formValue={formValue}
          />
        );
      case 2:
        return (
          <Step2
            setStep={setStep}
            style={style}
            errors={errors}
            register={register}
            control={control}
            formValue={formValue}
          />
        );
    }
  };

  const renderButton = () => {
    switch (step) {
      case 1:
        return (
          <Button
            fullWidth={true}
            type="button"
            loading={loading}
            disabled={loading}
            label="Continue"
            onClick={handleContinue}
          />
        );
      case 2:
        return (
          <>
            {/* <Button
            fullWidth={true}
            type='button'
            loading={loading}
            disabled={loading}
            label="Previous"
            onClick={handlePrevious}
          /> */}
            <Button
              fullWidth={true}
              type="submit"
              loading={loading}
              disabled={loading}
              label="Sign Up"
            />
          </>
        );
    }
  };

  return (
    <div className={`${style.signUp} flex-wrap`}>
      <div className={style.signUp__sidebar}>
        <FormProvider {...methods}>
          <SignUpLeft setStep={setStep} checkStep1Valid={checkStep1Valid} />
        </FormProvider>
      </div>
      <div className={style.signUp__logo}>
        <Logo />
      </div>
      <div className={style.signUp__content}>
        <form
          className={style.signUp__inner}
          onSubmit={handleSubmit(handleSignUp)}
          noValidate
        >
          {renderStep()}
          <div className={style.signUp__bottom}>
            {renderButton()}
            <div className={style.signUp__login}>
              Alreday have an account?
              <span onClick={() => navigate("/login")}> Log In</span>
            </div>
          </div>
        </form>
      </div>
      <div className={style.signUp__alignment}></div>
    </div>
  );
};

export default SignUp;
