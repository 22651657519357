import styled from '@emotion/styled';
import { mq } from '../../utils/mediaQuery'
import { Row } from 'antd'

export const ReactDiffViewer = styled.div((props) => `
    position: relative;

    tbody > tr {
        background: ${props.theme.token.colorBgLayout};
        padding: ${props.theme.token.paddingMD}px;
        padding-top: ${3 * props.theme.token.paddingXL}px;
        border-radius: ${props.theme.token.borderRadiusLG}px;
        box-shadow: ${props.theme.token.boxShadow};
        max-height: 500px;
        overflow: auto;
        margin-top: ${props.theme.token.paddingSM}px;
    }

    ${mq.up("md")} {
        tbody {
            display: flex;
            flex-direction: row-reverse;
            gap: ${props.theme.token.paddingMD}px;
    
            > tr {
                flex-grow: 1;
                flex-basis: 50%;
            }
        }
    }
    
    td > a {
        display: inline-block;
        cursor: not-allowed;
        pointer-events: none;
        color: black;
        text-decoration: none!important;
    }

    .identical {
        width: 100%;
        font-size: 5em;
        text-align: center;
        display: block;
    }
`)

export const DiffTitle = styled(Row)((props) => `
    position: absolute;
    width: calc(100% + ${props.theme.token.paddingMD}px);
    top: 0;
    left: 0;
    display: none;

    .ant-typography {
        margin: 0;
        padding: ${props.theme.token.paddingMD}px;
        border-bottom: 1px solid ${props.theme.token.colorBorderSecondary};
        background: ${props.theme.token.colorBgLayout};
        border-radius: ${props.theme.token.borderRadiusLG}px ${props.theme.token.borderRadiusLG}px 0 0;
    }

    ${mq.up("md")} {
        display: flex;
    }
`)