import styled from '@emotion/styled'
import { mq } from '../../utils/mediaQuery'

export const UserContainer = styled.div(() => `
    display: none;

    ${mq.up("md")} {
        display: flex;
    }
`)

export const UserInfo = styled.div(() => `
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .ant-typography {
        line-height: 1.2;
    }
`)