import styled from '@emotion/styled'
import { Avatar, Card } from 'antd'

export const CardComponent = styled(Card)((props) => `
    border-radius: ${props.theme.token.borderRadiusLG}px;
    box-shadow: ${props.theme.token.boxShadow};
    .ant-card-body {
        height: 100%;
    }
`)

export const IconAvatar = styled(Avatar)((props) => `
    border-radius: ${props.theme.token.borderRadius}px;
    background-color: ${props.theme.token.colorInfoBg};
    color: ${props.theme.token.colorInfoText};
    font-size: ${props.theme.token.fontSizeXL}px;
`)