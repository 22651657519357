import React, { useState } from "react";
import style from "./Table.module.scss";
import moreIcon from "../../assets/images/more.svg";
import doc from "../../assets/images/doc.svg";
import docGray from "../../assets/images/doc-gray.svg";
import emptyState from "../../assets/images/empty-state.svg";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import Select from "../../atoms/Select/Select";

export interface TableHeaderProps extends TableActionType {
  name: string;
  type: TableDataType;
  actionType?: TableActionType[];
  format?: string;
  title?: string;
  handleSort?: (columnName: string) => void;
}

export enum TableDataType {
  TEXT = "text",
  NUMBER = "number",
  ACTIONS = "action",
}

export enum ActionType {
  DELETE = "DELETE",
  ADD = "ADD",
  VIEW = "VIEW",
  EDIT = "EDIT",
  DOTS = "Dots",
  REFRESH = "REFRESH",
}

export interface TableActionType {
  icon?: string;
  name: ActionType | string;
  onClick?: (rowData: any) => void;
  onChange?: (rowData: any, value: string, keyThatIsChanged: string) => void;
  customClass?: string;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
}

export interface TableProps {
  headings: TableHeaderProps[];
  tableData: any[];
  outerBorder?: boolean;
  verticalScroll?: boolean;
  scrollHeight?: any;
  selectedId?: string | number | null | undefined;
  selectedfieldName?: string;
}

const renderIcon = (actionName: string) => {
  switch (actionName) {
    case ActionType.DELETE:
      return <img src={"TrashIcon"} alt={actionName} />;
    case ActionType.VIEW:
      return "View";
    case ActionType.REFRESH:
      return "Refresh";
    case ActionType.ADD:
      return <img src={"AddIcon"} alt={actionName} />;
    case ActionType.EDIT:
      return <img src={"EditIcon"} alt={actionName} />;
    case ActionType.DOTS:
      return (
        <div className={style.table__action}>
          {false && (
            <div className={style.table__options}>
              <button>Delete</button>
            </div>
          )}
          <picture>
            <img src={moreIcon} alt="more" />
          </picture>
        </div>
      );
    default:
      return null;
  }
};

export const dateConversion = (date: string) => {
  if (date) {
    const dateValue = new Date(date);
    const formattedDate = dateValue ? dateValue.toLocaleDateString() : "-";
    return formattedDate;
  } else {
    return "-";
  }
};

const Table: React.FC<TableProps> = ({
  headings,
  tableData,
  scrollHeight = "none",
  selectedId = "",
  selectedfieldName = "",
}) => {
  const [dropdownVisible, setDropdownVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const setDropdownVisibleForRow = (rowId: string, isVisible: boolean) => {
    setDropdownVisible((prev) => {
      const newDropdownVisible: { [key: string]: boolean } = {};
      Object.keys(prev).forEach((key) => {
        newDropdownVisible[key] = false;
      });
      newDropdownVisible[rowId] = isVisible;
      return newDropdownVisible;
    });
  };

  const renderCell = (row: any, column: TableHeaderProps) => {
    const {
      name,
      type,
      customClass,
      customStyle,
      actionType,
      onClick = () => {},
    } = column;
    if (name === "contract_name") {
      return (
        <td key={name}>
          <div className={`${style.table__icon} flex align-center`}>
            <img src={row.status === "Processed" ? doc : docGray} alt="" />
            {row[name] ?? "-"}
          </div>
        </td>
      );
    }
    if (name === "status") {
      return (
        <td
          key={name}
          className={customClass}
          style={customStyle}
          onClick={() => onClick(row)}
        >
          <div
            className={`${style.table__status} ${
              row.status === "Processed" && style.active
            }`}
          >
            {row[name] ?? "-"}
          </div>
        </td>
      );
    }

    switch (type) {
      case TableDataType.ACTIONS:
        return (
          <td key={name} className={customClass} style={customStyle}>
            <div className="flex align-center justify-end">
              {actionType?.map((action, actionIndex) => {
                if (action.name === ActionType.DOTS) {
                  return (
                    <DropdownMenu
                      key={actionIndex}
                      menuState={setDropdownVisible}
                    >
                      <div className={style.table__action}>
                        {dropdownVisible[row.id] && (
                          <div
                            className={style.table__options}
                            onClick={() =>
                              setDropdownVisibleForRow(row.id, false)
                            }
                          >
                            <button
                              onClick={() => {
                                if (action.onClick) {
                                  action.onClick({ ...row, delete: true });
                                }
                              }}
                            >
                              Delete
                            </button>
                            <button
                              onClick={() => {
                                if (action.onClick) {
                                  action.onClick({ ...row, edit: true });
                                }
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                        <picture
                          onClick={() =>
                            setDropdownVisibleForRow(
                              row.id,
                              !dropdownVisible[row.id]
                            )
                          }
                        >
                          <img src={moreIcon} alt="more" />
                        </picture>
                      </div>
                    </DropdownMenu>
                  );
                }
                // if (
                //   action.name === ActionType.VIEW &&
                //   row.status === "Not Processed"
                // ) {
                //   return (
                //     <Tooltip
                //       html={
                //         <>
                //           <strong>Note about turnaround time:</strong>
                //           <p>
                //             Brief typically takes 1-2 weeks to review and
                //             process any documents that have been uploaded.
                //           </p>
                //         </>
                //       }
                //       position="top"
                //       trigger="mouseenter"
                //       // @ts-ignore
                //       children={
                //         <button
                //           disabled
                //           key={actionIndex}
                //           className={`${action.customClass} ${style.table__view}`}
                //         >
                //           {renderIcon(action.name)}
                //         </button>
                //       }
                //     />
                //   );
                // }
                if (
                  action.name === ActionType.VIEW &&
                  row.status === "Processed"
                ) {
                  return (
                    <>
                      <button
                        key={actionIndex}
                        className={`${action.customClass} ${style.table__view}`}
                        onClick={() => {
                          if (action.onClick) {
                            action.onClick(row);
                          }
                        }}
                      >
                        {renderIcon(action.name)}
                      </button>
                    </>
                  );
                }
                return (
                  <button
                    key={actionIndex}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick(row);
                      }
                    }}
                    className={`${action.customClass} ${style.table__view} flex align-center`}
                    style={action.customStyle}
                  >
                    {renderIcon(ActionType.REFRESH)}
                  </button>
                );
              })}
            </div>
          </td>
        );
      default:
        function convertDateFormat(dateString: string) {
          const [datePart, timePart] = dateString.split(' ');
          const [year, month, day] = datePart.split('-');
          const formattedDate = `${month}.${day}.${year.slice(2)}`;
          return formattedDate;
        }
        let value;
        if (name === 'created_at') {
          value = convertDateFormat(row[name])
        } else {
          value = row[name]
        }
        return (
          <td
            key={name}
            className={customClass}
            style={customStyle}
            onClick={() => onClick(row)}
          >
            {value ?? "-"}
          </td>
        );
    }
  };

  return (
    <div className={style.table}>
      <div
        style={{
          maxHeight: scrollHeight,
        }}
      >
        <div>
          <table>
            <thead>
              <tr>
                {headings.map((header, index) => (
                  <th key={index}>
                    <div className="flex align-center">{header?.title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={
                      selectedId && selectedId === row[selectedfieldName]
                        ? "active"
                        : ""
                    }
                  >
                    {headings.map((column) => renderCell(row, column))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className={style.isNoData} colSpan={25}>
                    <div className={style.table__nodata}>
                      <img src={emptyState} alt="No data" />
                      <h4>No documents</h4>
                      <p>Upload a contract to get started</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
