import style from "./Logo.module.scss";
import LogoIcon from "../../assets/images/logo-icon.png";
import LogoText from "../../assets/images/logo-text.svg";
import LogoImage from "../../assets/images/logo.svg";
import { useNavigate } from "react-router";

const Logo = ({ primary = false }: { primary?: boolean }) => {

  const navigate = useNavigate();
  return (
    <div className={`${style.logo} flex align-center`}>
      {primary ? (
        <img onClick={() => navigate("/dashboard")} className={style.logo__main} src={LogoImage} alt="Brief" />
      ) : (
        <>
          <img className={style.logo__icon} src={LogoIcon} alt="B" onClick={() => navigate("/dashboard")}/>
          <img className={style.logo__text} src={LogoText} alt="Brief" onClick={() => navigate("/dashboard")}/>
        </>
      )}
    </div>
  );
};

export default Logo;
