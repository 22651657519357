import React, { Suspense, useEffect, useState } from "react";
import GeneralLayout from "../../layouts/GeneralLayout.js";
import { Col, Skeleton, Space, Typography } from "antd";
import { CardComponent } from "../../components/components.style.js";
import { ControllerRow } from "./ComparisonForm.style.js";
import DiffViewer from "../../components/DiffViewer";
import DocumentTemplate from "./DocumentTemplate/index.js";
import UserContent from "./UserContent/index.js";
import { ComparisonProvider } from "./ComparisonProvider.js";

function ComparisonForm() {
  const [selectedDocTitle, setSelectedDocTitle] = useState('Y Combinator SAFE');
  return (
    <GeneralLayout>
      <ComparisonProvider>
        <Space direction="vertical" size="large">
          <div>
            <Typography.Title level={3}>
              Welcome to the Brief Document Comparison Tool!
            </Typography.Title>
            <Typography>
              Track changes in your SAFE by uploading your SAFE and comparing it
              against standard Y Combinator templates. By uploading your
              document, you are agreeing to our terms and conditions. Please
              note that we reserve the right to utilize and aggregate anonymized
              data to improve the customer experience for everyone (including
              yourself). If you do not agree to these terms, we ask that you
              please do not use our services.
            </Typography>
          </div>
          <Space direction="vertical" size="large">
            <ControllerRow gutter={20}>
              <Col md={12}>
                <CardComponent>
                  <DocumentTemplate setSelectedDocTitle={setSelectedDocTitle}/>
                </CardComponent>
              </Col>
              <Col md={12}>
                <CardComponent>
                  <UserContent />
                </CardComponent>
              </Col>
            </ControllerRow>
            <Suspense fallback={<Skeleton active />}>
              <DiffViewer
                leftTitle={selectedDocTitle}
                rightTitle="Your Contract"
              />
            </Suspense>
          </Space>
        </Space>
      </ComparisonProvider>
    </GeneralLayout>
  );
}

export default ComparisonForm;
