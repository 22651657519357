import * as yup from "yup";

export const resetPasswordSchema = yup.object().shape({
  password: yup.string().required('Password is required').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    'Password must contain at least 8 characters, a number, a special character, a lowercase character, and an uppercase character'
  ),
  code: yup.string()
  .required("Code is required")
  .trim()
  .matches(/^\d{6}$/, "Confirmation code must be exactly 6 digits"),
});
