import React from "react";
import style from "./Initials.module.scss";

interface InitialsProps {
  name: string;
}

const Initials: React.FC<InitialsProps> = ({ name }) => {
  const getInitials = (name: string): string => {
    const initials = name?.split(" ")?.map((word) => word[0])?.join("")?.toUpperCase();

    return initials[0];
  };

  return <UserAvatar initials={getInitials(name)} />;
};

interface UserAvatarProps {
  initials: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ initials }) => {
  return <div className={style.initials}>{initials}</div>;
};

export default Initials;
