import { SidebarTab } from "../interfaces/interface";

export const SIDEBAR_TABS: SidebarTab[] = [
    {
      name: "Home",
      path: "/dashboard",
    },
    // {
    //   name: "Contracts",
    //   path: "/dashboard/contracts",
    // },
    // {
    //   name: "Help Resources",
    //   path: "/dashboard/help-resources",
    // },
    // {
    //   name: "Message Center",
    //   path: "/dashboard/message-center",
    // },
  ];