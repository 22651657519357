import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import style from "./Select.module.scss";

const Select = ({
  name,
  placeholder,
  prefixIcon,
  formValue,
  errorMessage = "",
  options,
  control,
}: {
  prefixIcon?: any;
  name: string;
  placeholder: string;
  options: any;
  control: any;
  errorMessage: string;
  formValue?: any
}) => {
  const selected = !!formValue?.[name];

  return (
    <div
      className={`${style.inputField} ${!prefixIcon? style.noPrefix : ''}`}
    >
      {selected && <div className={style.inputField__label}>{placeholder}</div>}
      {prefixIcon && (
        <div className={`${style.inputField__icon}`}>
          <img src={prefixIcon} alt="icon" />
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <select {...field} className={`${selected? style.selected: ""}`}>
              <option value="" disabled selected>
                {placeholder}
              </option>
              {options.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )
        }}
      />
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  )
}

export default Select;