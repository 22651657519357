import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import angleDown from "../../assets/images/angle-down.svg";
import Initials from "../../atoms/Initials/Initials";
import Logo from "../../atoms/Logo/Logo";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import { capitalizeFirstLetter } from "../../constants/constants";
import { StorageEnums } from "../../enums/storageEnums";
import { CognitoService } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import { UserService } from "../../services/user.service";
import {
  selectUserDetails,
  setUserDetails,
} from "../../store/userDetailsSlice";
import style from "./Header.module.scss";
import { refreshTokenFunction } from "../../services/apiServices";

const userService = new UserService();
const service = new CognitoService();
export interface headerProp {
  bottomBorder?: boolean;
  fullWidth?: boolean;
  showNav?: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
}
const Header = ({
  bottomBorder = true,
  fullWidth = false,
  showNav,
  setShowNav,
}: headerProp) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const [loading, setLoading] = useState<boolean>(false);

  const storageService = new StorageService();
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const [userName, setUserName] = useState<string>("");

  const logout = () => {
    storageService.removeKey(StorageEnums.CREDENTIALS);
    navigate("/login");
  };

  const getProfile = useCallback(async () => {
    setLoading(true);
    const userJSON = localStorage.getItem(StorageEnums.CREDENTIALS);
    if (!userJSON) {
      return;
    }
    const user = JSON.parse(userJSON);
    try {
      let userDetail = await userService.getUserProfile(
        user?.accessToken?.jwtToken
      );
      setUserName(userDetail?.data?.name);
    } catch (err) {
      console.error("Error fetching user profile", err);
      await handleTokenRefresh(user);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const handleTokenRefresh = async (user: any) => {
    try {
      const { refreshToken: { token: refreshToken }, idToken: { payload: { email } } } = user;
      await refreshTokenFunction(email, refreshToken);
      const updatedUserDetail = await userService.getUserProfile(user?.accessToken?.jwtToken);
      setUserName(updatedUserDetail?.data?.name);
    } catch (refreshErr) {
      console.error("Error during token refresh and re-fetch:", refreshErr);
    }
  }

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    if (!userName) {
      getProfile();
    }
  }, [userName])

  return (
    <header
      className={`${style.header} ${fullWidth && style.isFull} ${
        !bottomBorder && style.noBorder
      } flex justify-end align-center`}
    >
      <div className={style.header__logo}>
        <Logo />
      </div>
      {loading ? (
        <div className={`${style.header__profileLoading} flex align-center`}>
          <Skeleton width="32px" height="32px" radius="50%" />
          <Skeleton margin="0 0 0 10px" width="70px" />
        </div>
      ) : (
        <>
          {storageService.getKey(StorageEnums.CREDENTIALS) && (
            <DropdownMenu menuState={setDropdownVisible}>
              <div className={style.header__profile}>
                <div
                  className={`${style.header__profileHolder} flex align-center`}
                  onClick={toggleDropdown}
                >
                  <Initials name={capitalizeFirstLetter(userName ?? "")} />
                  <aside>
                    <strong>{capitalizeFirstLetter(userName ?? "")}</strong>
                    <img src={angleDown} alt="V" />
                  </aside>
                </div>
                <ul
                  onClick={() => setDropdownVisible(false)}
                  className={isDropdownVisible ? style.active : ""}
                >
                  <li onClick={logout}>Logout</li>
                </ul>
              </div>
            </DropdownMenu>
          )}
        </>
      )}

      <a
        onClick={() => {
          setShowNav(!showNav);
          setDropdownVisible(false);
        }}
        className={`${style.header__hamburger} ${showNav && style.active}`}
      >
        <span></span>
      </a>
    </header>
  );
};
export default Header;
