import style from "./Table.module.scss";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import { TableHeaderProps } from "./Table";
export interface TableLoaderProps {
  outerBorder?: boolean;
  rows: number;
  columns: number;
  headings: TableHeaderProps[];
}
const TableLoader = ({
  outerBorder = true,
  rows,
  columns,
  headings,
}: TableLoaderProps) => {
  return (
    <div className={style.table}>
      <table>
        <thead>
          <tr>
            {headings.map((header, index) => (
              <th key={index}>
                <div className="flex align-center">{header?.title}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array(rows)
            .fill(0)
            .map((item, index) => {
              return (
                <tr key={index}>
                  {Array(columns)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <td key={index}>
                          <Skeleton height="10px" margin="0" />
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TableLoader;
