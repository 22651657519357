import style from "./Modal.module.scss";
import CloseIcon from "../../assets/images/cross.svg";
import { useEffect } from "react";

const Modal = ({
  title = "",
  children,
  footer,
  showModal = false,
  modalWidth = "635px",
  showModalFunction,
  closeModalOnBackdropClick = true,
}: {
  title?: string;
  children: any;
  footer?: any;
  showModal: boolean;
  modalWidth?: string;
  showModalFunction: Function;
  closeModalOnBackdropClick?: boolean;
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal])

  const handleBackdropClick = (e: any) => {
    if (closeModalOnBackdropClick) {
      showModalFunction(false);
    }
  }

  return (
    <div className={`${style.modal} ${showModal && style.active}`} onClick={handleBackdropClick}>
      <div className={style.modal__backdrop}></div>
      <div className={style.modal__wrap} style={{ maxWidth: modalWidth }}>
        <header className={style.modal__header}>
          {title && <h4>{title}</h4>}
          <img
            onClick={() => showModalFunction(false)}
            src={CloseIcon}
            alt="X"
          />
        </header>
        <div className={style.modal__content}>{children}</div>
        {footer && (
          <div className={`${style.modal__footer} flex`}>{footer}</div>
        )}
      </div>
    </div>
  );
};
export default Modal;
