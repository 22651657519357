import React, { useContext } from "react";
import DiffViewerLib, { DiffMethod } from "react-diff-viewer-continued";
import { DiffTitle, ReactDiffViewer } from './DiffViewer.style'
import { ComparisonContext } from '../../pages/ComparisonForm/ComparisonProvider'
import { Col, Typography } from 'antd'
import useSyncScroll from './useSyncScroll'

function DiffViewer({ leftTitle, rightTitle, ...libProps }) {
  const {
    templateStates: [templateDoc],
    contractStates: [contractDoc],
  } = useContext(ComparisonContext);

  useSyncScroll();

  return (
    <ReactDiffViewer>
        <DiffTitle gutter={20}>
            <Col md={12}>
                <Typography.Title level={4}>{leftTitle}</Typography.Title>
            </Col>
            <Col md={12}>
                <Typography.Title level={4}>{rightTitle}</Typography.Title>
            </Col>
        </DiffTitle>
        <DiffViewerLib
            {...libProps}
            oldValue={contractDoc}
            newValue={templateDoc}
            splitView={false}
            useDarkTheme={false}
            compareMethod={DiffMethod.WORDS_WITH_SPACE}
            codeFoldMessageRenderer={()=> {
                return (
                    <div className="identical">💼 Identical Match! 💼</div>
                );
            }}
            styles={{
                gutter: {
                    display: 'none',
                },
                marker: {
                    display: 'none',
                },
                diffContainer: {
                    background: 'transparent',
                },
                codeFoldGutter: {
                    display: 'none',
                },
                codeFold: {
                    height: 'fit-content',
                }
            }}
        />
    </ReactDiffViewer>
  );
}

export default DiffViewer;
