import { theme } from 'antd'

export default function useTheme() {
    const { token } = theme.useToken()
    // Ant Design allows you to customize design tokens including primary color, border radius, border color, etc.
    // For a full list of token variables, please refer to: https://ant.design/docs/react/customize-theme#api
    // You can also use online theme editor here: https://ant.design/theme-editor
    return {
        token: {
            ...token,
            colorBgLayout: '#FFFFFF',
            colorBgElevated: '#F6F9FC',
            borderRadiusLG: 16,
            boxShadow: `0 0 9px rgba(0, 0, 0, 0.05)`
        },
    }
}
