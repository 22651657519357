import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import authImg from "../../assets/images/auth-image.png";
import LogoIcon from "../../assets/images/logo-icon.png";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import Logo from "../../atoms/Logo/Logo";
import { CognitoService } from "../../services/auth.service";
import { confirmPassword } from "./confirmPassword.schema";
import { toast } from "react-toastify";
import LockIcon from "../../assets/images/lock.svg";

const ConfirmSignup = () => {
  const service = new CognitoService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { email = "" } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(confirmPassword),
    mode: "onChange",
  });

  const formValue = watch();

  const handleConfirmPassword = async (data: any) => {
    try {
      setLoading(true);
      const res = await service.cognitoConfirmSignup(
        data.confirmationCode,
        email
      );
      setLoading(false);
      toast.success("Account is confirmed!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      navigate("/login");
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      console.log({ ...error });
    }
  };

  const resendCode = async () => {
    try {
      setLoading(true);
      const res = service.resendConfirmationCode(email);
      toast.success("A verification code has been resent.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      reset();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <section className={`${style.auth} flex-wrap`}>
        <aside className={style.auth__content}>
          <Logo />
          <div className={style.auth__block}>
            <div className={style.auth__header}>
              <img src={LogoIcon} alt="B" />
              <strong>Confirm your email</strong>
              <span>Enter confirmation code</span>
            </div>

            <form onSubmit={handleSubmit(handleConfirmPassword)} noValidate>
              <Input
                type="text"
                label="Confirmation Code"
                placeholder="Confirmation Code"
                register={register}
                registerName="confirmationCode"
                value={formValue.confirmationCode}
                errorMessage={errors?.confirmationCode?.message}
                prefixIcon={LockIcon}
              />

              <Button
                type="submit"
                fullWidth={true}
                loading={loading}
                disabled={loading}
                label={"Confirm Code"}
              />
              <div className={`${style.auth__resend} flex justify-center`}>
                <a onClick={resendCode}>Resend code</a>
              </div>
            </form>
          </div>
          <div className={style.auth__alignment}></div>
        </aside>
        <aside className={`${style.auth__image} flex align-end`}>
          <img src={authImg} alt="Image" />
        </aside>
      </section>
    </>
  );
};

export default ConfirmSignup;
