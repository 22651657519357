import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useHideHeader = () => {
  const location = useLocation();

  const hideSideBar = useMemo(() => {
    const { pathname } = location;
    return !["/dashboard"].includes(pathname.toLowerCase());
  }, [location]);
  return hideSideBar;
};
export default useHideHeader;
