import { yupResolver } from "@hookform/resolvers/yup";
import { Viewer } from "@react-pdf-viewer/core";
import { useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import "react-pdf/dist/Page/TextLayer.css";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Skeleton from "../../../atoms/Skeleton/Skeleton";
import Modal from "../../../components/Modal/Modal";
import { StorageEnums } from "../../../enums/storageEnums";
import { ApiService } from "../../../services/apiServices";
import Summary from "../Summary/Summary";
import EditDocument from "./EditContract/EditDocumet";
import { editDocumentSchema } from "./EditContract/editDocument.schema";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import EditIcon from "../../../assets/images/edit_square.svg";
import InfoIcon from "../../../assets/images/info-o.svg";
import { StorageService } from "../../../services/storage.service";
import Note from "./Components/Note";
import style from "./Documents.module.scss";
interface DocumentData {
  id: string;
  s3_key: string;
}

// interface ContractData {
//   documents: DocumentData[];
// }
const storageService = new StorageService();
const specialChrachter = [
  "(",
  ")",
  "+",
  "*",
  "?",
  "^",
  "$",
  "[",
  "]",
  "{",
  "}",
  "|",
];

const Documents = () => {
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );
  const { id, docId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForEditDoc, setLoadingForEditDoc] = useState<boolean>(false);
  const [contractData, setContractData] = useState(null);
  const [docLink, setDocLink] = useState<string>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [foundText, setFoundText] = useState<RegExp[]>([]);
  const [insights, setInsights] = useState<any[]>([]);
  const [insightSelected, setInsightSelected] = useState<any>(null);
  const [insightSelectedRegex, setInsightSelectedRegex] = useState<RegExp>(
    new RegExp("$.^")
  );
  const [showMore, setShowMore] = useState(false);
  const [showMoreNotes, setShowMoreNotes] = useState("");
  // count to force refresh
  const [currentFeedback, setCurrentFeedback] = useState(0);
  const [pageHeight, setPageHeight] = useState<number>(0);

  const navigate = useNavigate();
  const zoomPluginInstance = zoomPlugin();

  const notesRef = useRef<any[]>([]);
  const notesIDRef = useRef<{ id: string; pageNumber: number; top: number }[]>(
    []
  );

  const getDocLink = async () => {
    setLoading(true);
    if (docId && docId !== "summary") {
      try {
        const obj = {
          url: `documents/${docId}`,
          headerToken: `${localStorageUser.accessToken.jwtToken}`,
        };
        let documents = await new ApiService().get(obj);
        setDocLink(documents?.data?.download_url);
        const visibleInsights = documents?.data?.insights.filter(
          (insight: any) => !insight.hidden
        );
        setInsights(visibleInsights);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const textTollerance = (searchedText: string, tollerance: number) => {
    let newText = searchedText;
    specialChrachter.forEach((chr: string) => {
      newText = newText.replaceAll(chr, "\\" + chr);
    });
    const regexPattern = newText
      .split(" ")
      .map((word: string) => `${word}\\s{0,${tollerance}}`)
      .join("");

    const regex = new RegExp(regexPattern);
    return regex;
  };

  useEffect(() => {
    if (insightSelected) {
      const regex = textTollerance(insightSelected.sentences, 1);
      setInsightSelectedRegex(regex);
    }
  }, [insightSelected]);

  const handleFeedbackDown = (insight: any) => {
    const filtered = insights.filter((each: any) => {
      if (each.dev_tool_insights_id !== insight.dev_tool_insights_id)
        return each;
    });
    setInsights(filtered);
  };

  function groupByKeywordAndPage(data: any[]) {
    const map = new Map();
    for (const item of data) {
      const key = `${item.keywordStr}-${item.pageIndex}`;
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push(item);
    }
    return Array.from(map.values());
  }

  const searchPluginInstance = searchPlugin({
    // enableShortcuts: true,
    keyword: foundText,
    renderHighlights: (renderProps) => {
      const uniqueHightlightAreas = groupByKeywordAndPage(
        renderProps.highlightAreas
      );
      return (
        <>
          {uniqueHightlightAreas.map((group, groupIndex) => {
            let keywordStr = group[0].keywordStr
              .replace(/\\s\{0,1\}/g, " ")
              .trim();
            specialChrachter.forEach((chr: string) => {
              keywordStr = keywordStr.replaceAll("\\" + chr, chr);
            });
            return group.map((area: any, index: number) => {
              if (Math.ceil(area.pageHeight) !== Math.ceil(pageHeight)) {
                setPageHeight(area.pageHeight);
              }
              const renderNotePads = () => {
                // only locate the first line of the sentence and render notes based on the first line
                if (index === 0) {
                  let matchingInsights = insights.filter(
                    (el) => el.sentences === keywordStr
                  );
                  // each sentence might have multiple insights
                  return matchingInsights.map((insight, idx) => {
                    let notesCumulativeHeigth = 0;
                    let currentPage = -1;
                    //Calculating and updateing top for each note
                    notesIDRef.current.forEach((noteId: any) => {
                      if (
                        notesRef?.current?.[noteId.id] &&
                        notesRef?.current?.[noteId.id]?.current
                      ) {
                        let styleProperty = notesRef.current[noteId.id].current
                          .getAttribute("style")
                          ?.split(";")
                          ?.filter((ela: string) => !ela.includes("top"));
                        let areaTop = (area.pageHeight * noteId.top) / 100;
                        let calculatedTop = 0;
                        //for same page and new page
                        if (
                          currentPage === noteId.pageNumber ||
                          currentPage === -1
                        ) {
                          if (notesCumulativeHeigth > areaTop) {
                            calculatedTop = notesCumulativeHeigth;
                          } else {
                            calculatedTop = areaTop;
                          }
                        } else {
                          //if previous page notes height is greater than page height
                          if (notesCumulativeHeigth > area.pageHeight) {
                            calculatedTop =
                              areaTop > notesCumulativeHeigth - area.pageHeight
                                ? areaTop
                                : notesCumulativeHeigth - area.pageHeight;
                          } else {
                            calculatedTop = areaTop;
                          }
                        }
                        notesCumulativeHeigth =
                          notesRef.current[noteId.id].current.offsetHeight +
                          calculatedTop +
                          5;
                        notesRef.current[noteId.id].current.setAttribute(
                          "style",
                          `${styleProperty?.join("")}; top:${calculatedTop}px`
                        );
                        currentPage = noteId.pageNumber;
                      }
                    });

                    if (
                      !notesIDRef.current
                        .map((el) => el.id)
                        .includes(insight.dev_tool_insights_id as string)
                    ) {
                      notesIDRef.current.push({
                        id: insight.dev_tool_insights_id,
                        pageNumber: area.pageIndex,
                        top: area.top,
                      });
                    }
                    return (
                      <Note
                        getDocLink={getDocLink}
                        insight={insight}
                        index={index}
                        InfoIcon={InfoIcon}
                        groupIndex={groupIndex}
                        area={area}
                        renderProps={renderProps}
                        setInsightSelected={setInsightSelected}
                        notesRef={notesRef}
                        showMore={showMoreNotes}
                        setShowMore={setShowMoreNotes}
                        handleFeedbackDown={handleFeedbackDown}
                        setCurrentFeedback={setCurrentFeedback}
                        currentFeedback={currentFeedback}
                      />
                    );
                  });
                }
              };

              return (
                <>
                  <div
                    key={`${area.pageIndex}-${index}`}
                    id={`${area.pageIndex}-${groupIndex}-${index}`}
                    className={`${style.documents__selection} ${
                      insightSelected?.sentences === keywordStr
                        ? style.selected
                        : ""
                    } ${
                      insights.every(
                        (each: any) => each.sentences !== keywordStr
                      )
                        ? style.thumbsdown
                        : ""
                    }`}
                    style={{
                      ...renderProps.getCssProperties(area),
                    }}
                  ></div>
                  {renderNotePads()}
                </>
              );
            });
          })}
        </>
      );
    },
  });

  useEffect(() => {
    updateNotesPosition();
  }, [showMoreNotes, currentFeedback]);

  const updateNotesPosition = () => {
    let currentPage = -1;
    let notesCumulativeHeigth = 0;
    notesIDRef.current.forEach((noteId: any) => {
      // find the expand ref, and update all ref after it

      if (
        notesRef?.current?.[noteId.id] &&
        notesRef?.current?.[noteId.id]?.current
      ) {
        let styleProperty = notesRef.current[noteId.id].current
          .getAttribute("style")
          ?.split(";")
          ?.filter((ela: string) => !ela.includes("top"));
        let areaTop = (pageHeight * noteId.top) / 100;
        let calculatedTop = 0;
        //for same page and new page
        if (currentPage === noteId.pageNumber || currentPage === -1) {
          if (notesCumulativeHeigth > areaTop) {
            calculatedTop = notesCumulativeHeigth;
          } else {
            calculatedTop = areaTop;
          }
        } else {
          //if previous page notes height is greater than page height
          if (notesCumulativeHeigth > pageHeight) {
            calculatedTop =
              areaTop > notesCumulativeHeigth - pageHeight
                ? areaTop
                : notesCumulativeHeigth - pageHeight;
          } else {
            calculatedTop = areaTop;
          }
        }
        notesCumulativeHeigth =
          notesRef.current[noteId.id].current.offsetHeight + calculatedTop + 5;
        notesRef.current[noteId.id].current.setAttribute(
          "style",
          `${styleProperty?.join("")}; top:${calculatedTop}px`
        );
        currentPage = noteId.pageNumber;
      }
    });
  };

  const secondSearchInstance = searchPlugin();
  const { highlight } = secondSearchInstance;
  useEffect(() => {
    // highlight({ keyword: "Sterling" });
  }, [insightSelectedRegex]);

  useEffect(() => {
    const backtoHome = () => {
      navigate("/");
    };
    window.addEventListener("popstate", backtoHome);
    return () => {
      window.removeEventListener("popstate", backtoHome);
    };
  }, [navigate]);

  const methods = useForm({
    resolver: yupResolver(editDocumentSchema),
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (file) {
      methods.clearErrors("file");
    }
  }, [file]);

  async function getDocsTabs() {
    try {
      setLoading(true);
      const obj = {
        url: `contracts/${id}`,
        headerToken: `${localStorageUser.accessToken.jwtToken}`,
      };
      let contracts = await new ApiService().get(obj);
      setContractData(contracts?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (insights.length > 0) {
      const replacedTexts = insights
        .filter((insight) => !!insight.sentences)
        .map((highlight) => highlight.sentences);
      const uniqueStrings = [...new Set(replacedTexts)];

      const regexPatterns = uniqueStrings.map((text) =>
        textTollerance(text, 1)
      );
      setFoundText(regexPatterns);
    }
  }, [insights]);

  useEffect(() => {
    if (docId) getDocLink();
  }, [docId]);
  useEffect(() => {
    const abc = () => {
      setInsightSelected(null);
    };
    document.addEventListener("click", abc);
    getDocsTabs();
    return () => {
      document.removeEventListener("click", abc);
    };
  }, []);

  if (docId && docId == "summary" && !loading) {
    return <>{contractData && <Summary contracts={contractData} />}</>;
  }

  const handleEdit = async () => {
    setFile("");
    setModalShow(true);
    methods.reset();
  };

  const handleEditDocumenttSubmit = async (data: FieldValues) => {
    try {
      setLoadingForEditDoc(true);
      const obj = {
        data,
        url: `documents/${docId}`,
        headerToken: `${localStorageUser?.accessToken?.jwtToken}`,
      };
      let docUploadUrl = await new ApiService().put(obj);
      if (file) {
        const obj1 = {
          url: docUploadUrl.data.upload_url,
          data: file,
          PresignedUrl: true,
          contentType: file.type,
        };
        await new ApiService().put(obj1);
        toast.success("Document updated successfully");
        const event = new Event("documentUpdated");
        window.dispatchEvent(event);
      }

      setModalShow(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForEditDoc(false);
    }
  };

  const renderPage = (props: any) => {
    const renderNonSentencesInsight = () => {
      if (props.pageIndex === 0) {
        const startInsights = insights.filter((insight) => {
          return !insight.sentences;
        });
        return (
          <>
            {/* <div className="flex flex-col h-full"> */}
            {startInsights.map((insight, index) => {
              const { summary, title, explanation, insight_type } = insight;
              let newHeight = 0;
              for (let i = 0; i < index; i++) {
                newHeight =
                  newHeight +
                  (index > 0
                    ? document.getElementById(`startInsight-${i}`)
                        ?.offsetHeight || 0
                    : 0) +
                  10;
              }
              const customStyle = {
                top: `${newHeight}px`,
              };
              return (
                <div
                  className={style.documents__textBox}
                  key={`startInsight-${index}`}
                  id={`startInsight-${index}`}
                  style={{
                    ...customStyle,
                  }}
                  onClick={() => setInsightSelected(insight)}
                >
                  <article className={showMore ? style.active : ""}>
                    <header className="flex align-center">
                      <span>{summary || title}</span>
                    </header>
                    <p>{explanation}</p>
                  </article>

                  <div>
                    <a onClick={() => setShowMore(true)}>
                      {showMore ? "Less more" : "Show more"}
                    </a>
                  </div>
                  {showMore && (
                    <button className="button">
                      <img src={InfoIcon} alt="i" />
                      {insight_type}
                    </button>
                  )}
                </div>
              );
            })}
            {/* </div> */}
          </>
        );
      }
    };

    return (
      <>
        {props.canvasLayer.children}
        {/* {renderNonSentencesInsight()} */}
        {props.annotationLayer.children}
        {props.textLayer.children}
      </>
    );
  };

  return (
    <div className={style.documents}>
      {loading ? (
        <div className={style.documents__skeleton}>
          <Skeleton width="100" height="100px" margin="20px 0 0 0" />
        </div>
      ) : (
        <>
          <div
            className={`${style.documents__edit} flex justify-end`}
            id="cdi-1"
          >
            {/* <a onClick={handleEdit}>
              <img src={EditIcon} alt="Edit" />
            </a> */}
          </div>
          {docLink && (
            <div className={style.documents__pdf}>
              <Viewer
                fileUrl={docLink}
                plugins={[
                  searchPluginInstance,
                  zoomPluginInstance,
                  secondSearchInstance,
                ]}
                renderPage={renderPage}
                renderLoader={(percentages: number) => {
                  if (percentages === 100) {
                  }
                  return (
                    <div className={style.documents__skeleton}>
                      <Skeleton
                        width="100"
                        height="100px"
                        margin="20px 0 0 0"
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}
          {modalShow && (
            <Modal
              modalWidth={"500px"}
              showModal={modalShow}
              showModalFunction={setModalShow}
              title="Edit Document"
            >
              <FormProvider {...methods}>
                <EditDocument
                  onSubmit={handleEditDocumenttSubmit}
                  loading={loadingForEditDoc}
                  setFile={setFile}
                  file={file}
                />
              </FormProvider>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default Documents;
