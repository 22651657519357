import { StorageEnums } from "../../enums/storageEnums";
import { CognitoService } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logInSchema } from "../Login/login.schema";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import style from "../../assets/scss/auth.module.scss";
import authImg from "../../assets/images/auth-image.png";
import Logo from "../../atoms/Logo/Logo";
import LogoIcon from "../../assets/images/logo-icon.png";
import CopyIcon from "../../assets/images/copy-svgrepo-com.svg";
import Header from "../../components/Header/Header";
import Layout from "../../components/Layout/Layout";
import Sidebar from "../../components/SideBar/Sidebar";
import { SIDEBAR_TABS } from "../../constants/sidebar.constants";
import { Outlet } from "react-router";


const Admin = () => {
  const service = new CognitoService();
  const storageService = new StorageService();
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [showNav, setShowNav] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(logInSchema),
  });

  useEffect(() => {
    const userJSON = localStorage.getItem(StorageEnums.CREDENTIALS);
    if (userJSON) {
      const user = JSON.parse(userJSON);
      setUser(user);
    }
  }, []);

  const handleLogin = async (data: any) => {
    setLoading(true);
    try {
      const res = await service.cognitoLogin(data.email, data.password);
      setUser(res);
      setLoading(false);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const handleCopy = async () => {
    const token = user?.accessToken?.jwtToken;
    await navigator.clipboard.writeText(token);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000)
  }

  const tokenHtml = (
    <div className={style.auth__copyWrap}>
      <div className={style.auth__copyInnerWrap}>
        <strong>Bearer Token</strong>
        <div className={style.auth__copy}>
          <span>{copied ? '✅ Copied!' : 'Click to copy'}
          </span>
          <img src={CopyIcon} onClick={handleCopy} />
        </div>
        <div className={style.auth__token}>{user?.accessToken?.jwtToken}</div>
      </div>
    </div>
  )

  return (
    <>
      {user ? (
        <>
          <Header
            bottomBorder={true}
            showNav={showNav}
            setShowNav={setShowNav}
          />
          <Layout
            sidebar={
              <Sidebar
                SidebarTabData={SIDEBAR_TABS}
                showNav={showNav}
                setShowNav={setShowNav}
              />
            }
            content={tokenHtml}
          />
        </>

      ) : (
        <section className={`${style.auth} flex-wrap`}>
          <aside className={style.auth__content}>
            <Logo />
            <div className={style.auth__block}>
              <div className={style.auth__header}>
                <img src={LogoIcon} alt="B" />
                <strong>Welcome Back</strong>
                <span>Enter your Brief account details</span>
              </div>
              <div>
                <form onSubmit={handleSubmit(handleLogin)} noValidate>
                  <Input
                    type="email"
                    label="Email address"
                    placeholder="Email address"
                    register={register}
                    registerName="email"
                    errorMessage={errors?.email?.message}
                  />
                  <Input
                    type="password"
                    label="Password"
                    placeholder="Password"
                    register={register}
                    registerName="password"
                    marginBottom={false}
                    errorMessage={errors?.password?.message}
                  />
                  <div className={style.auth__secalignment} />
                  <Button
                    type="submit"
                    fullWidth={true}
                    loading={loading}
                    disabled={loading}
                    label={"SIGN IN"}
                  />
                </form>
              </div>
            </div>
            <div className={style.auth__alignment}></div>
          </aside>
          <aside className={`${style.auth__image} flex align-end`}>
            <img src={authImg} alt="Image" />
          </aside>
        </section>
      )}
    </>
  )
}

export default Admin;