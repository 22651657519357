import Highlights from "./Highlights";
import HighlightsHeading from "./HighlightsHeading";
import style from "./Summary.module.scss";

const Summary = ({ contracts }: any) => {
  return (
    <div className={`${style.summary} flex-col`}>
      <HighlightsHeading contracts={contracts} />
      <Highlights contracts={contracts} />
    </div>
  );
};

export default Summary;
