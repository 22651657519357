import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserDetails {
  cognitoId: string;
  email: string;
  name: string;
  id: number;
}

interface UserDetailsState {
  details: UserDetails;
}

const initialState: UserDetailsState = {
  details: {
    cognitoId: '',
    email: '',
    name: '',
    id: 0,
  },
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.details = action.payload;
    },
  },
});

export const { setUserDetails } = userDetailsSlice.actions;
export const selectUserDetails = (state: { userDetails: UserDetailsState }) =>
  state.userDetails.details;

export default userDetailsSlice.reducer;
