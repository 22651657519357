import styled from '@emotion/styled'
import { Row, Upload } from 'antd'

export const ControllerRow = styled(Row)((props) => `
    .ant-col {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > .ant-card {
            flex-grow: 1;
        }
    }

    .ant-form-item {
        margin-bottom: ${props.theme.token.marginXS}px;
    }

    .ant-form-item-control-input {
        min-height: 1px;
    }
`)

export const ControllerRowItem = styled.div((props) => `
    display: flex;
    align-items: center;
    gap: ${props.theme.token.paddingSM}px;
    height: 100%;
`)

export const ControllerRowItemForm = styled.div(() => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: space-between;
`)

export const DocumentUpload = styled(Upload)(() => `
    .ant-upload.ant-upload-select {
        display: block;
    }
`)

export const TemplateReferenceBox = styled.div(() => `
margin-bottom: 15px;

`)

export const DownloadDiv = styled.a(props => ({
    textDecoration: 'underline',
    color: props.color || 'black',
    textDecorationColor: '#1476ff',
    fontWeight: 'bold',
  }));

  export const CustomSpace = styled.div(props => ({
    height: props.height || '20px',
  }))
