
export function capitalizeFirstLetter(inputString: string): string {
    return inputString?.charAt(0)?.toUpperCase() + inputString?.slice(1);
  }

export const shortenName = (name: string) => {
    const maxFileNameLength = 20;
    if (name.length < maxFileNameLength) {
      return name;
    }
    else {
      const startLength = Math.floor((maxFileNameLength - 3) / 2);
      const endLength = maxFileNameLength - startLength - 3;
      name =
        name.substring(0, startLength) +
        "..." +
        name.substring(name.length - endLength);
      return name;
    }
  
  }