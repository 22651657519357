import { createContext, useState } from 'react'

export const ComparisonContext = createContext();
export const ComparisonProvider = (props) => {
    const { children } = props;
    const [templateDoc, setTemplateDoc] = useState("Select a standard document from the list below to compare against. " +
    "Differences will highlight in these boxes!");
    const [contractDoc, setContractDoc] = useState('Click "Choose File" below to upload your document.');
    const providerProps = {
        templateStates: [templateDoc, setTemplateDoc],
        contractStates: [contractDoc, setContractDoc],
    }

    return (
        <ComparisonContext.Provider value={providerProps}>
            {children}
        </ComparisonContext.Provider>
    )
}