import * as yup from "yup";

export const logInSchema = yup.object().shape({
  email: yup
  .string()
  .email("Please enter a valid email.")
  .required("Email is required")
  .trim()
  .matches(
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    "Please enter a valid email."
  ),
  password: yup.string().required("Password is required"),
});

export const feedbackSchema = yup.object().shape({
  feedback: yup.string().required("Please enter your feedbacks").trim(),
})
