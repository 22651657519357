import styled from '@emotion/styled'
import { Layout, Menu } from 'antd'

export const LayoutBody = styled.div(() => `
    max-width: 1024px;
    margin: 0 auto;
    flex-grow: 1;
`)

export const LayoutFooter = styled(Layout.Footer)(() => `
    text-align: center;
`)

export const LayoutHeader = styled(Layout.Header)((props) => `
    background-color: ${props.theme.token.colorBgLayout};
    display: flex;
    align-items: center;
`)

export const LayoutSider = styled(Layout.Sider)((props) => `
    && {
        background-color: ${props.theme.token.colorBgLayout};
        border-right: ${props.theme.token.lineWidth}px solid ${props.theme.token.colorBorderSecondary};
        box-shadow: ${props.theme.token.boxShadow};
        position: fixed;
        z-index:9;
        height: 100%;
        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
        }

        .ant-menu {
            border-right: none;
        }
    }
`)

export const LayoutContent = styled(Layout.Content)((props) => `
    background-color: ${props.theme.token.colorBgElevated};
    padding: ${props.theme.token.paddingMD}px;
    padding-bottom: ${props.theme.token.paddingMD}px;
`)

export const LayoutMenuContainer = styled.div((props) => `
    padding-left: ${props.theme.token.paddingMD}px;
    padding-right: ${props.theme.token.paddingMD}px;
`)

export const LayoutMenu = styled(Menu)((props) => `
    text-align: left;
    padding-top: ${props.theme.token.paddingMD}px;
    padding-bottom: ${props.theme.token.paddingMD}px;
    margin-bottom: ${props.theme.token.paddingMD}px;
    border-top: ${props.theme.token.lineWidth}px solid ${props.theme.token.colorBorderSecondary};
    border-bottom: ${props.theme.token.lineWidth}px solid ${props.theme.token.colorBorderSecondary};
    min-height: 140px;

    .ant-menu-item {
        margin: 0;
        width: 100%;
    }
`)

export const LayoutSiderAction = styled.div((props) => `
    padding: ${props.theme.token.paddingMD}px;
    flex-grow: 1;
`)

export const LogoContainer = styled.div((props) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props.theme.token.paddingMD}px;

    img {
        display: block;
        max-width: 80px;
        height: auto;
    }
`)

export const HeaderSection = styled.div((props) => `
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
        font-size: ${props.theme.token.fontSizeLG}px;
    }
`)

export const HeaderTools = styled.div((props) => `
    display: flex;
    align-items: center;
    gap: ${props.theme.token.paddingMD}px;
`)