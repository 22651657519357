import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Tab } from "../../interfaces/interface";
import style from "./HorizontalTabBar.module.scss";

export interface HorizontalTabBarProps {
  tabs: Tab[];
  onScroll?: any;
}

const HorizontalTabBar: React.FC<HorizontalTabBarProps> = ({
  tabs,
  onScroll,
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className={style.horizontalTabs}>
      <ul onScroll={onScroll}>
        {tabs?.map((tab: Tab) => (
          <li key={tab.value} onClick={() => setToggle(false)}>
            <NavLink to={`${tab.value}`}>
              {({ isActive, isPending }) => (
                <div className={isActive ? style.active : ""}>{tab.name}</div>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HorizontalTabBar;
