import React, { useMemo } from "react";
import Logo from "../../atoms/Logo/Logo";
import { useFormContext } from "react-hook-form";
import style from "./SignUpLeft.module.scss";

const SignUpLeft = ({
  setStep,
  checkStep1Valid,
}: {
  setStep: any;
  checkStep1Valid: any;
}) => {
  const { watch } = useFormContext();
  const {
    fullName,
    email,
    password,
    businessName,
    city,
    state,
    fundraisingStage,
    industry,
    yearlyRevenue,
    agreeToTerms,
    frequencyOfSigningContracts,
  } = watch();

  const isStep1Valid = useMemo(() => {
    return fullName?.trim() !== "" && email?.trim() !== "" && password;
  }, [fullName, email, password]);

  const isStep2Valid = useMemo(() => {
    return (
      businessName?.trim() &&
      city?.trim() &&
      state &&
      fundraisingStage &&
      industry.trim() &&
      yearlyRevenue &&
      agreeToTerms &&
      frequencyOfSigningContracts
    );
  }, [
    businessName,
    city,
    state,
    fundraisingStage,
    industry,
    yearlyRevenue,
    agreeToTerms,
    frequencyOfSigningContracts,
  ]);

  const handleGoStep1 = () => {
    setStep(1);
  };

  const handleGoStep2 = async () => {
    const valid = await checkStep1Valid();
    if (valid) setStep(2);
  };

  return (
    <div className={style.signUpLeft}>
      <div className={style.signUpLeft__logo}>
        <Logo />
      </div>

      <div
        className={`${style.signUpLeft__item} ${
          isStep1Valid ? style.isActive : ""
        }`}
        onClick={handleGoStep1}
      >
        <div className={style.signUpLeft__check}>
          <input type="radio" checked={isStep1Valid} />
          <span></span>
        </div>
        <h4>Personal Details</h4>
        <p>Please provide your name, email and password</p>
      </div>
      <div
        className={`${style.signUpLeft__item} ${
          isStep2Valid ? style.isActive : ""
        }`}
        onClick={handleGoStep2}
      >
        <div className={style.signUpLeft__check}>
          <input type="radio" checked={isStep2Valid} />
          <span></span>
        </div>
        <h4>Business Information</h4>
        <p>Complete the sign up process</p>
      </div>
    </div>
  );
};

export default SignUpLeft;
