import { createRef, useEffect, useState } from "react";
import style from "./../Documents.module.scss";
import ThumbsUp from "../../../../assets/images/thumb-up.svg";
import ThumbsDown from "../../../../assets/images/thumb-down.svg";
import Cross from "../../../../assets/images/cross.svg"
import InsightMissingType from "../../../../assets/images/insight-missing-type.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackSchema } from "../../../Login/login.schema";
import Input from "../../../../atoms/Input/Input";
import { ApiService } from "../../../../services/apiServices";
import Button from "../../../../atoms/Button/Button";
import { StorageEnums, InsightTypeEnums } from "../../../../enums/storageEnums";
import { current } from "@reduxjs/toolkit";

const Note = ({
  area,
  groupIndex,
  index,
  renderProps,
  setInsightSelected,
  insight,
  InfoIcon,
  notesRef,
  showMore,
  setShowMore,
  handleFeedbackDown,
  setCurrentFeedback,
  getDocLink,
  currentFeedback,
}: {
  area: any;
  groupIndex: number;
  index: number;
  renderProps: any;
  setInsightSelected: any;
  insight: any;
  InfoIcon: any;
  notesRef: any;
  showMore: any;
  setShowMore: any;
  handleFeedbackDown: any;
  getDocLink: any;
  setCurrentFeedback: any;
  currentFeedback: any;
  //   ref:any
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    resolver: yupResolver(feedbackSchema),
  });

  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedbackSelected, setFeedbackSelected] = useState(0);

  const apiSrvice = new ApiService();

  const { summary, explanation, insight_type, title } = insight;
  const insightType = InsightTypeEnums[insight_type as keyof typeof InsightTypeEnums];
  const handleExpand = () => {
    setShowMore(
      showMore === insight.dev_tool_insights_id
        ? ""
        : insight.dev_tool_insights_id
    )
  }

  const handleCloseFeedbackBox = () => {
    setFeedbackOpen(false);
    setCurrentFeedback(currentFeedback + 1);
    setFeedbackSelected(0);
    reset();
  }

  useEffect(() => {
    setCurrentFeedback(currentFeedback + 1);
  }, [errors])

  const handleSubmitFeedback = async (data: any) => {
    try {
      await openEmailClient(data);
      if (feedbackSelected === 2) {
        await handleFeedbackDownApi();
      }
      handleCloseFeedbackBox();
    } catch (err) {
      console.error(err);
    }
  }

  const openEmailClient = async (data: any) => {
    const subject = "Brief Feedback";
    const recipient = "help@briefme.io";
    const { feedback } = data;
    const feedbackType = feedbackSelected === 1? "Thumbs Up" : "Thumbs Down";
    const fullFeedback = `Insight ID: ${insight.dev_tool_insights_id}\nFeedback Type: ${feedbackType}\nFeedback Content:\n${feedback}`
    const encodedFeedback = encodeURIComponent(fullFeedback);
    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${encodedFeedback}`;
    window.open(mailtoLink, '_blank');
  }

  const handleFeedbackDownApi = async () => {
    const obj = {
      url: `insights/legacy/${insight.dev_tool_insights_id}`,
      headerToken: `${localStorageUser.accessToken.jwtToken}`,
      data: {
        hidden: true,
      }
    }
    try {
      await apiSrvice.put(obj);
      // await getDocLink();
      handleFeedbackDown(insight);
    } catch (err) {
      console.error(err);
    }
  }

  const handleThumbsUp = (e: any) => {
    e.stopPropagation();
    setCurrentFeedback(currentFeedback + 1);
    if (feedbackSelected === 1) {
      handleCloseFeedbackBox();
    } else {
      setFeedbackOpen(true);
      setFeedbackSelected(1);
    }
  }

  const handleThumbsDown = (e: any) => {
    e.stopPropagation();
    setCurrentFeedback(currentFeedback + 1);
    if (feedbackSelected === 2) {
      handleCloseFeedbackBox();
    } else {
      setFeedbackOpen(true);
      setFeedbackSelected(2);
    }
  }


  return (
    <div
      style={{
        ...renderProps.getCssProperties(area),
      }}
      ref={
        notesRef.current[insight.dev_tool_insights_id]
          ? notesRef.current[insight.dev_tool_insights_id]
          : (notesRef.current[insight.dev_tool_insights_id as any] =
            createRef())
      }
      className={style.documents__textBox + " insight_elment"}
      key={`${groupIndex}-${area.pageIndex}-${index}`}
      onClick={(e) => {
        e.stopPropagation();
        setInsightSelected(insight);
        document
          .getElementById(`${area.pageIndex}-${groupIndex}-${index}`)
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      }}
    >
      <div
        className={style.mainBox}
      >
        <article
          className={
            showMore === insight.dev_tool_insights_id ? style.active : ""
          }
        >
          <header className="flex align-center">
            <span>{summary || title}</span>
          </header>
          <p>{explanation}</p>
        </article>

        {showMore === insight.dev_tool_insights_id && (
          <>
            <button className={`button ${insight_type === "missing" ? "missing" : "other-type"}`}>
              <img src={insight_type === "missing" ? InsightMissingType : InfoIcon} alt="i" />
              {insightType}
            </button>
            <div
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className={`button feedback ${feedbackSelected === 1 ? 'selected' : ''}`}
                onClick={handleThumbsUp}
              >
                <img src={ThumbsUp} alt="thumb up" />
              </button>
              <button className={`button feedback ${feedbackSelected === 2 ? 'selected' : ''}`}>
                <img src={ThumbsDown} alt="thumb down" onClick={handleThumbsDown} />
              </button>
            </div>
          </>
        )}
        <div>
          <a
            onClick={(e) => {
              e.stopPropagation();
              handleExpand();
            }}
          >
            {showMore === insight.dev_tool_insights_id
              ? "Show less"
              : "Show more"}
          </a>
        </div>
      </div>
      {feedbackOpen && (
        <form
          className={style.feedbackBox}
          onSubmit={handleSubmit(handleSubmitFeedback)}
          noValidate
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <header className="flex align-center justify-between">
            <span>Details</span>
            <img src={Cross} onClick={handleCloseFeedbackBox} alt="close" />
          </header>
          <Input
            register={register}
            registerName="feedback"
            type="textArea"
            placeholder="Enter details here...  "
            errorMessage={errors.feedback?.message}
          />
          <Button
            type="submit"
            fullWidth={true}
            loading={loading}
            disabled={loading}
            label={"SEND"}
          />
        </form>
      )}
    </div>
  );
};
export default Note;
