import { useEffect, useState } from "react";
import style from "./HighlightsHeading.module.scss";
import userIcon from "../../../assets/images/user-icon.svg";
import summarySheild from "../../../assets/images/summary-shield.svg"
import { shortenName } from "../../../constants/constants";
import { YEARLY_REVENUE } from "../../../constants/signup.constants";

export interface HighlightsType {
  id: string;
  type: string;
  title: string;
  summary: string;
  details: string;
  top_highlight: boolean;
}

const HighlightsHeading = ({ contracts }: any) => {
  const [topHighlights, setTopHighlights] = useState<HighlightsType[]>();
  const getStoplightColor = () => {
    switch (contracts.stoplight_color) {
      case "Yellow":
        return style['stoplight-yellow']
    }
  }
  useEffect(() => {
    let highlights = contracts?.highlights?.filter((el: any) => {
      return el.top_highlight;
    });
    setTopHighlights(highlights);
  }, []);

  const findSafeInsightBasedOnType = (type: string) => {
    if (contracts) {
      const found = contracts?.insights?.safe?.find((each: any) => each["safe_insight_types_id"] === type);
      return found?.insight_value;
    }
  }

  const findIssueDate = () => {
    const found = findSafeInsightBasedOnType("DATE_TYPE_ID");
    function formatDateString(dateString: any) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
      }
      const year = date.getFullYear();
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      return {
        date: `${month} ${day}`,
        year: year.toString()
      };
    }
    return formatDateString(found)

  }

  const issueDate = findIssueDate();
  const investmentAmount = "$" + findSafeInsightBasedOnType("INVESTMENT_ID");
  const valuationAmount = findSafeInsightBasedOnType("VALUATION_ID");
  const valuationType = findSafeInsightBasedOnType("VALUATION_TYPE_ID")
  const investorName = findSafeInsightBasedOnType("INVESTOR_ID");
  const companyName = findSafeInsightBasedOnType("COMPANY_ID");

  return (
    <div className={style.Highlights}>
      <h3>{contracts?.name}</h3>
      <div className={`${style.Highlights__row} flex-wrap`}>
        <div className={`${style.Highlights__terms} flex align-center`}>
          <img src={userIcon} alt="" />
          <aside>
            <strong>{`${investorName} & ${companyName}`}</strong>
          </aside>
        </div>
        <div className={`${style.Highlights__caution} ${getStoplightColor()}`}>
          <ul>
            <li></li>
            <li className={`${getStoplightColor()}`}></li>
            <li></li>
          </ul>
          <span>Key Highlights Need Review</span>
        </div>
        <div className={style.Highlights__summary}>
          <div className="flex align-center">
            <img src={summarySheild} alt="summary" />
            <h4>
              Summary
            </h4>
          </div>
          <p>This document is requesting a board observer seat, MFN, Pro Rata Rights and Information Rights.</p>
        </div>
        <div className={style.Highlights__box}>
          <p>Issue Date</p>
          <h1>{issueDate?.date}</h1>
          <h2>{issueDate?.year}</h2>
        </div>
        <div className={style.Highlights__box}>
          <p>Investment</p>
          <h1>{investmentAmount}</h1>
        </div>
        <div className={style.Highlights__box}>
          <p>Valuation</p>
          <h1>{valuationAmount}</h1>
          <h2>{valuationType}</h2>
        </div>
      </div>
    </div>
  );
};

export default HighlightsHeading;
