import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Table, { ActionType, TableDataType } from "../../components/Table/Table";
import { HOME_CONTRACTS_HEADINGS } from "../../constants/home.constants";
import style from "./Home.module.scss";
import briefcase from "../../assets/images/briefcase.svg";
import uploadIcon from "../../assets/images/upload.svg";
import addIcon from "../../assets/images/add.svg";
import arrowDown from "../../assets/images/arrow-down.svg"
import tickIcon from "../../assets/images/tick.svg";
import crossIcon from "../../assets/images/cross-white.svg";
import { toast } from "react-toastify";
import { ApiService } from "../../services/apiServices";
import { selectUserDetails } from "../../store/userDetailsSlice";
import { useSelector } from "react-redux";
import { StorageEnums } from "../../enums/storageEnums";
import GlobalLoader from "../../components/GlobalLodaer/GlobalLoader";
import TableLoader from "../../components/Table/TableLoader";
import { shortenName } from "../../constants/constants";
import Modal from "../../components/Modal/Modal";
import EditContract from "../EditContract/EditContract";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editcontractSchema } from "../EditContract/editContract.schema";
import styled from "@emotion/styled";
import { Input } from "antd";
import { StorageService } from "../../services/storage.service";
import * as yup from "yup";

const createContractSchema = yup.object().shape({
  contractType: yup.string().required("Please select an option")
})

interface FileObject {
  file_name: string;
  file_size: string;
  file_name_with_size: string;
}

interface ContractData {
  contract_name: string;
  numberOfFiles: number;
  fileSize: string;
  status: string;
  id: number;
}

interface ContractsTypes {
  label: string;
  value: string;
}

// const fileOptions = [
//   {
//     label: 'SAFE Financing',
//     value: 'SAFE Financing',
//   },
//   {
//     label: 'NDA',
//     value: 'NDA',
//   },
//   {
//     label: 'MSA/Vendor Agreement',
//     value: 'MSA/Vendor Agreement',
//   },
//   {
//     label: 'Insurance Policy',
//     value: 'Insurance Policy',
//   },
//   {
//     label: 'Other',
//     value: 'Other',
//   },
// ]

const Home = () => {
  const storageService = new StorageService();
  const apiService = new ApiService();
  const [files, setFiles] = useState<FileObject[]>([]);
  const [filesForUpload, setFilesForUpload] = useState<File[]>([]);
  const [loading, setLoading] = useState({
    loading: false,
    loadingForTable: false,
    globalLoading: false,
    loadingForbutton: false,
  });
  const [contracts, setContracts] = useState<ContractData[]>([]);
  const [contractTypes, setContractTypes] = useState<ContractsTypes[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const userDetails = useSelector(selectUserDetails);
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [editableId, setEditableId] = useState<string>("");
  const [contractName, setContractName] = useState<string>("");
  const [disclaimerModal, setDisclamerModal] = useState<boolean>(false);

  const methods = useForm({
    resolver: yupResolver(editcontractSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  const methods2 = useForm({
    resolver: yupResolver(createContractSchema)
  })

  const handleSubmit2 = methods2.handleSubmit;
  const control2 = methods2.control;
  const watch2 = methods2.watch;
  const formValue = watch2();

  useEffect(() => {
    getContracts();
    getContractTypes();
  }, []);

  const getContractTypes = async () => {
    try {
      const response = await apiService.get({
        url: "contracts-types"
      })
      const contractTypesData = JSON.parse(response?.data?.body);
      const contractTypes = contractTypesData.types.map((each: {id: string, name: string}) => {
        return {
          label: each.name,
          value: each.id,
        }
      })
      setContractTypes(contractTypes);
    } catch (err) {
      console.error(err);
    }
  }


  const getContracts = async () => {
    setLoading((prev) => ({
      ...prev,
      loadingForTable: true,
    }));

    try {
      const obj = {
        url: "contracts",
        headerToken: `${localStorageUser.accessToken.jwtToken}`,
      };
      let contracts = await apiService.get(obj);
      let contractData = contracts.data.contracts.map((el: any) => {
        return {
          contract_name: el.name,
          numberOfFiles: el.num_files,
          fileSize: "20mb",
          status: el.processed ? "Processed" : "Processing",
          id: el.id,
          created_at: el.created_at.split(".")[0],
        };
      });
      setContracts(contractData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingForTable: false,
      }));
    }
  };

  const handleInputContractName = (e: ChangeEvent<HTMLInputElement>) => {
    setContractName(e.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const allowedTypes = ["application/pdf"];

      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      if (allowedTypes.includes(files[0].type)) {
        onFileChange(files[0]);
      } else {
        toast.error("Please upload a valid document file (PDF).");
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const allowedTypes = ["application/pdf"];

      // "application/msword",
      //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      if (allowedTypes.includes(files[0].type)) {
        onFileChange(files[0]);
      } else {
        toast.error("Please upload a valid document file (PDF, DOC, DOCX).");
      }
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleMailToClick = () => {
    window.location.href = "mailto:feedback@briefme.io";
  };

  const onFileChange = async (file: File) => {
    setLoading((prev) => ({
      ...prev,
      globalLoading: true,
    }));

    try {
      const fileSizeInBytes = file.size;
      const fileSizeInKB = fileSizeInBytes / 1000;
      const fileSizeInMB = fileSizeInKB / 1000;

      if (fileSizeInMB > 2) {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        toast.error(
          <div>
            This document is too large to process right now. Please contact{" "}
            <span className={style.email} onClick={handleMailToClick}>
              feedback@briefme.io
            </span>{" "}
            so we can better understand how to.
          </div>
        );
      } else {
        let truncatedFileName = shortenName(file.name);
        const fileSizeString = `${fileSizeInKB.toFixed(2)} KB`;
        const fileNameWithSize = `${truncatedFileName} | ${fileSizeString}`;
        setFilesToUpload((prev) => [...prev, file]);

        const fileInfo = {
          file_name: file.name,
          file_size: file.size.toString(),
          file_name_with_size: fileNameWithSize,
        };
        setFilesForUpload((prev) => [...prev, file]);
        setFiles((prevFileNames) => [...prevFileNames, fileInfo]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error("Error handling file:", error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        globalLoading: false,
      }));
    }
  };

  const handleCloseDisclaimer = async () => {
    setDisclamerModal(false);
    await handleSummary();
  };

  const handlerViewContract = useCallback(
    (rowData: any) => {
      if (rowData.status === "Processed") {
        navigate(`/dashboard/contracts/${rowData?.id}`);
      } else {
        getContracts();
      }
    },
    [navigate]
  );

  const handleDeleteContract = async (rowData: any) => {
    try {
      const obj = {
        data: { hidden: true },
        url: `contracts/${rowData.id}`,
        headerToken: `${localStorageUser?.accessToken?.jwtToken}`,
      };
      await new ApiService().put(obj);
      setTimeout(() => {
        getContracts();
      }, 300);
      toast.success("Contract deleted successfully");
      setModalShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditContract = async (rowData: any) => {
    setEditableId(rowData.id);
    setModalShow(true);
  };

  const handleEditContractSubmit = async (data: FieldValues) => {
    try {
      setLoading((prev) => ({
        ...prev,
        loadingForbutton: true,
      }));

      const obj = {
        data,
        url: `contracts/${editableId}`,
        headerToken: `${localStorageUser?.accessToken?.jwtToken}`,
      };
      let contracts = await new ApiService().put(obj);
      setTimeout(() => {
        getContracts();
      }, 300);
      toast.success("Contract edit successfully");
      setModalShow(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingForbutton: false,
      }));
    }
  };

  const handleDotsClick = async (rowData: any) => {
    if (rowData.delete) {
      handleDeleteContract(rowData);
    }
    if (rowData.edit) {
      methods.reset();
      handleEditContract(rowData);
    }
  };

  const contractsHeadings = useMemo(() => {
    return HOME_CONTRACTS_HEADINGS.map((el) => {
      if (el.type === TableDataType.ACTIONS) {
        el?.actionType?.forEach((e) => {
          if (e.name === ActionType.VIEW) {
            e.onClick = (rowData) => handlerViewContract(rowData);
          }
          if (e.name === ActionType.DOTS) {
            e.onClick = (rowData) => handleDotsClick(rowData);
          }
        });
      }
      return el;
    });
  }, [handlerViewContract, handleDotsClick]);

  const handleDeleteFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    const updatedFilesForUpload = [...filesToUpload];
    updatedFilesForUpload.splice(index, 1);
    setFilesToUpload(updatedFilesForUpload);
  };

  const handleSummary = async () => {
    try {
      // setDisclamerModal(true);
      setLoading((prev) => ({
        ...prev,
        globalLoading: true,
      }));

      const uploadFile = async (elem: any, contractsId: any) => {
        const payload = {
          contracts_id: contractsId,
          display_name: elem.name,
          file_name: elem.name,
          content_type: elem.type,
        };
        const object = {
          url: "documents",
          data: payload,
          headerToken: localStorageUser.accessToken.jwtToken,
        };
        const response = await new ApiService().post(object);

        const obj1 = {
          url: response.data.upload_url,
          data: elem,
          PresignedUrl: true,
          contentType: elem.type,
        };
        await new ApiService().put(obj1);
      };
      const objForContractId = {
        url: "contracts",
        data: {
          name: contractName,
          type: formValue?.['contractType']
        },
        headerToken: localStorageUser.accessToken.jwtToken,
      };
      const resp = await new ApiService().post(objForContractId);

      await Promise.all(
        filesToUpload.map(async (elem, index) => {
          await uploadFile(elem, resp.data.contracts_id);
        })
      );

      setFiles([]);
      setFilesToUpload([]);
      setTimeout(() => {
        getContracts();
      }, 1000);

      setContractName("");
      toast.success("Contract uploaded successfully");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        globalLoading: false,
      }));
    }
  };

  return (
    <div className={style.home}>
      <div className={`${style.home__intro} flex align-center`}>
        <img src={briefcase} alt="BriefCase" />
        <aside>
          <h1>Welcome to Brief!</h1>
          <p>Upload a Contract & Get Your Brief, Instantly</p>
        </aside>
      </div>
      <div className={style.home__group}>
        <div className={style.home__contract}>
          <header>
            <strong>1. Upload a Contract in PDF</strong>
            <p>Try uploading a contract to see the power of Brief</p>
          </header>

          <div className={`${style.home__contractContent} flex-wrap`}>
            <div
              className={style.home__upload}
              onClick={handleClick}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <div>
                <img src={uploadIcon} alt="upload icon" />
                <p>
                  <span>Drag files here or</span> <strong>browse</strong>
                </p>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".pdf"
                />
              </div>
            </div>

            <aside className="flex-col align-end justify-between">
              <div className={style.home__uploaded}>
                {files.map((fileName, index) => (
                  <div
                    key={index}
                    className={`${style.home__file} flex align-center`}
                  >
                    <img src={tickIcon} alt="tick" />
                    <p>{fileName?.file_name_with_size}</p>
                    <img
                      className={style.isDelete}
                      src={crossIcon}
                      alt="delete"
                      onClick={() => handleDeleteFile(index)}
                    />
                  </div>
                ))}

                {!files.length && <div
                  className={`${style.home__add} flex align-center`}
                  onClick={handleClick}
                >
                  <img src={addIcon} alt="Add" />
                  <p>Add your first contract</p>
                </div>}
                {files.length < 2 && <div
                  className={`${style.home__add} flex align-center`}
                  onClick={handleClick}
                >
                  <img src={addIcon} alt="Add" />
                  <p>Add additional attachments (e.g., Side Letter)</p>
                </div>}
                {files.length > 1 && <div
                  className={`${style.home__add} flex align-center`}
                  onClick={handleClick}
                >
                  <img src={addIcon} alt="Add" />
                  <p>Add more</p>
                </div>}
              </div>
            </aside>
          </div>
        </div>
        <div className={style.home__contract}>
          <header>
            <strong>2. Name Your File</strong>
            <p>Select Contract Type & Name Your Folder</p>
          </header>

          <div className={`${style.home__contractContent} flex-col justify-between`}>
            <aside className="flex-col align-end justify-between">
              <div className={style.home__uploaded}>
                <div
                  className={`${style.inputField}`}
                >
                  <div className={`${style.inputField__icon}`}>
                    <img src={arrowDown} alt="icon" />
                  </div>
                  <Controller
                    name='contractType'
                    control={control2}
                    render={({ field }) => {
                      const selected = !!formValue?.['contractType'];
                      return (
                        <select
                          {...field}
                          className={`${selected ? style.selected : ""}`}
                          >
                          <option value="" disabled selected>
                             Select Contract Type
                          </option>
                          {contractTypes.map((option: any) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )
                    }}
                  />
                </div>
                <div>
                  <Input
                    className={`${style.home__contract_name}`}
                    value={contractName}
                    placeholder="Name Your File" size='large'
                    onChange={handleInputContractName}
                  />
                </div>
              </div>
            </aside>
            <div className={style.home__button}>
              <Button
                loading={loading.loading}
                onClick={() => setDisclamerModal(true)}
                buttonType="red-info"
                disabled={!filesToUpload.length || loading.loading || !contractName.trim() || !formValue.contractType}
                label="Get your Brief"
              />

            </div>
          </div>
        </div>
      </div>
      {loading.loadingForTable ? (
        <>
          <TableLoader
            rows={4}
            headings={contractsHeadings}
            columns={contractsHeadings.length}
          />
        </>
      ) : (
        <Table headings={contractsHeadings} tableData={contracts} />
      )}
      {loading.globalLoading && <GlobalLoader />}
      {modalShow && (
        <Modal
          modalWidth="400px"
          showModal={modalShow}
          showModalFunction={setModalShow}
          title="Edit Contract"
        >
          <FormProvider {...methods}>
            <EditContract
              onSubmit={handleEditContractSubmit}
              loading={loading.loadingForbutton}
            />
          </FormProvider>
        </Modal>
      )}
      {disclaimerModal && (
        <Modal
          modalWidth="700px"
          showModal={disclaimerModal}
          showModalFunction={() => setDisclamerModal(false)}
          title="Disclaimer"
          closeModalOnBackdropClick={false}
        >
          <p>
            This software is currently in its early development stages with
            experimental results. It is intended for informational purposes only
            and should not be considered a substitute for professional legal
            advice. Users are advised to consult with a qualified attorney
            regarding their specific legal concerns. By using this service, you
            agree that the software is not a replacement for legal counsel and
            that you will seek legal assistance as needed. Additionally, please
            be aware that your data may be stored and utilized in aggregate to
            enhance the quality of our services.
          </p>
          <br />
          <div className="flex justify-end">
            <Button onClick={handleCloseDisclaimer} label="I Agree" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Home;
