import { useMutation, useQuery } from "@tanstack/react-query";
import SparkMD5 from "spark-md5";

export const useDocumentOptionsQuery = () => {
  return useQuery({
    queryKey: ["document-options"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}standard-docs`
      );
      const json = await response.json();
      return json.docs;
    },
  });
};

export const useStandardDocMutation = ({ setNewValue, getText }) => {
  return useMutation({
    mutationKey: ["standard-doc"],
    mutationFn: async (docId) => {
      return await fetch(
        `${process.env.REACT_APP_API_URL}standard-docs/${docId}`
      );
    },
    onSuccess: async (response) => {
      const json = await response.json();
      if ("signed_url" in json) {
        getText(
          json.signed_url,
          json.file_type,
          (text) => {
            setNewValue(text);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    },
    onError: () => {
      throw new Error("Failed to get Doc url.");
    },
  });
};

export const useUploadSafeDocMutation = () => {
  return useMutation({
    mutationKey: ["upload-safe-doc"],
    mutationFn: async function uploadSafeDoc(file) {
      const file_name = file.name;
      const content_type = file.type;
      const spark = new SparkMD5.ArrayBuffer();
      return await file
        .arrayBuffer()
        .then((arrayBuf) => {
          spark.append(arrayBuf);
          return spark.end();
        })
        .then(async (hash) => {
          const data = {
            md5: hash,
            file_name: file_name,
            content_type: content_type,
          };
          return await fetch(`${process.env.REACT_APP_API_URL}safe-compare`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
        });
    },
    onSuccess: async (response, file) => {
        const json = await response.json();
        if (!json.file_exists) {
            const s3UploadUrl = json.signed_url;
            fetch(s3UploadUrl, {
              method: "PUT",
              mode: "cors",
              body: file,
            }).then((_response) => {
              if (!_response.ok) {
                throw new Error("Error uploading file to s3.");
              }
            });
        }
    },
    onError: (e) => {
        console.error(e);
        throw new Error("Error uploading file.");
    }
  });
};
