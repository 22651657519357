import { NavLink } from "react-router-dom";
import { SidebarTab } from "../../interfaces/interface";
import Logo from "../../atoms/Logo/Logo";
import HomeIcon from "../../assets/images/home.svg";
import HomeIconActive from "../../assets/images/home-active.svg";
import style from "./Sidebar.module.scss";
import { useNavigate } from "react-router-dom";

export interface SidebarProp {
  showNav: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
  SidebarTabData: SidebarTab[];
}

const Sidebar = ({ showNav, setShowNav, SidebarTabData }: SidebarProp) => {
  const navigate = useNavigate();
  return (
    <>
      <div onClick={(e) => {
          setShowNav(false);
          e.stopPropagation();
        }} className={`${style.sidebar} ${showNav && style.active}`}>
        <div className={style.sidebar__logo} onClick={() => navigate("/dashboard")}>
          <Logo primary={true}/>
        </div>
        {
          <nav>
            {SidebarTabData?.map((el: SidebarTab) => {
              return (
                <NavLink key={el.path} to={el.path}>
                  {({ isActive, isPending }) => (
                    <div className={isActive ? style.active : ""}>
                      <picture>
                        <img
                          src={isActive ? HomeIconActive : HomeIcon}
                          alt={el.name}
                        />
                      </picture>
                      {el.name}
                    </div>
                  )}
                </NavLink>
              );
            })}
          </nav>
        }
      </div>
      <div  onClick={(e) => {
          setShowNav(false);
          e.stopPropagation();
        }} className={`${style.sidebar__overlay} ${showNav && style.active}`}></div>
    </>
  );
};

export default Sidebar;
