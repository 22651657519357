import axios from "axios";
import { ApiDataObject } from "../interfaces/interface";
import { StorageService } from "./storage.service";
import { StorageEnums } from "../enums/storageEnums";
import { CognitoService } from "./auth.service";

const baseurl = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(
  async (config) => {
    if (config.headers.presigned) {
      config.headers.delete("presigned");
      return config;
    };
    try {
      const token = await getTokenAndRefreshIfNeed();
      config.headers.Authorization = token;
      return config;
    } catch (err) {
      console.error("Error fetching token:", err);
      return Promise.reject(err);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function refreshTokenFunction(email: string, refreshToken: string) {
  const service = new CognitoService();
  const storageService = new StorageService();
  try {
    const res: any = await service.refreshSession(email, refreshToken);
    console.log("refreshed tokens", res)
    storageService.setKey(StorageEnums.CREDENTIALS, res);
    return res?.accessToken?.jwtToken;
  } catch (error) {
    console.log("refresh token error: ", error);
    storageService.removeKey(StorageEnums.CREDENTIALS);
    window.location.href = "/login";
  }
}
function isTokenExpired(exp: any) {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= exp;
}

async function getTokenAndRefreshIfNeed() {
  let token;
  const storageService = new StorageService();
  const user = storageService.getKey(StorageEnums.CREDENTIALS)
  const isExpired = isTokenExpired(user?.accessToken?.payload?.exp);
  if (isExpired) {
    const { refreshToken: { token: refreshToken }, idToken: { payload: { email } } } = user;
    token = await refreshTokenFunction(email, refreshToken);
  } else {
    token = user?.accessToken?.jwtToken;
  }
  return token;
}

export class ApiService {
  async get(obj: ApiDataObject) {
    return await axios.get(`${baseurl}${obj.url}`);
  }
  async post(obj: ApiDataObject) {
    return await axios.post(`${baseurl}${obj.url}`, obj?.data);
  }

  async delete(obj: ApiDataObject) {
    return await axios.delete(`${baseurl}${obj.url}`, {
      params: obj?.data,
    });
  }
  async put(obj: ApiDataObject) {
    if (obj.PresignedUrl) {
      return await axios.put(obj.url, obj?.data, {
        headers: {
          "Content-Type": obj?.contentType,
           presigned: true,
        },
      });
    } else {
      return await axios.put(`${baseurl}${obj.url}`, obj?.data);
    }
  }
}
