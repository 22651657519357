// import { pdfjs } from "react-pdf";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import RouterPath from "./routes/RouterPath";
import ComparisonForm from "./pages/ComparisonForm";
import { ConfigProvider } from "antd";
import useTheme from "./themes/default.js";
import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Worker } from "@react-pdf-viewer/core";

import Plausible from "plausible-tracker";
const plausible = Plausible({
  domain: "app.briefme.io",
});

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {
  const theme = useTheme();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        networkMode: "always",
      },
      mutations: {
        retry: false,
        networkMode: "always",
      },
    },
  });
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/legacy/build/pdf.worker.js">
              <RouterPath />
            </Worker>
            <ToastContainer />
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
