import { ActionType, TableDataType, TableHeaderProps } from "../components/Table/Table";


export const HOME_CONTRACTS_HEADINGS: TableHeaderProps[] = [
  {
    name: "contract_name",
    title: "Recent Contracts ",
    type: TableDataType.TEXT,
    customClass: "",
    customStyle: {},
  },
  {
    name: "numberOfFiles",
    title: "Number Of Files",
    type: TableDataType.TEXT,
    customClass: "",
    customStyle: {},
  },
  // {
  //   name: "fileSize",
  //   title: "File Size",
  //   type: TableDataType.TEXT,
  //   customClass: "",
  //   customStyle: {},
  // },
  {
    name: "created_at",
    title: "Date Uploaded",
    type: TableDataType.TEXT,
    customClass: "",
    customStyle: {},
  },
  {
    name: "status",
    title: "Status",
    type: TableDataType.TEXT,
    customClass: "",
    customStyle: {},
  },

  {
    name: "action",
    title: "",
    type: TableDataType.ACTIONS,
    actionType: [
      {
        icon: "",
        name: ActionType.VIEW,
        onClick: (rowData: any) => { },
        customClass: "",
        customStyle: {},
      },
      {
        icon: "",
        name: ActionType.DOTS,
        onClick: (rowData: any) => { },
        customClass: "",
        customStyle: {},
      },
    ],
    customClass: "",
    customStyle: {},
  },
];
