import Input from "../../atoms/Input/Input";
import LogoIcon from "../../assets/images/logo-icon.png";
import ArrowBack from "../../assets/images/arrow-back.svg";
import UserIcon from "../../assets/images/user.svg";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import Select from "../../atoms/Select/Select";
import {
  FUNDRAISING_STAGE,
  YEARLY_REVENUE,
  STATES,
} from "../../constants/signup.constants";
import RadioBox from "../../atoms/RadioBox/RadioBox";

export enum FrequencyOfSigningContracts {
  ONCE_A_MONTH = "once_a_month",
  TWO_THREE_TIMES_A_MONTH = "two_three_times_a_month",
  THREE_PLUS_TIMES_A_MONTH = "three_plus_a_month",
  LESS_THAN_ONCE_A_MONTH = "less_than_once_a_month",
}

const Step2 = ({
  style,
  errors,
  register,
  control,
  formValue,
  setStep,
}: {
  style: any;
  errors: any;
  register: any;
  control: any;
  formValue: any;
  setStep: any;
}) => {
  const handleGoBack = () => {
    setStep(1);
  }
  return (
    <div className={style.signUp__block}>
      <div className={style.signUp__header}>
        <img src={LogoIcon} alt="B" />
        <strong>Business Information</strong>
        <span>Complete the sign up process</span>
      </div>
      <div className={style.signUp__back} onClick={handleGoBack}>
        <img src={ArrowBack} alt="back"/>
        <span>
        Back
        </span>
      </div>
      <Input
        type="text"
        placeholder="Business Name"
        register={register}
        registerName="businessName"
        errorMessage={errors?.businessName?.message}
        value={formValue.businessName}
      />
      <div className={style.signUp__row}>
        <Input
          type="text"
          placeholder="City"
          register={register}
          registerName="city"
          errorMessage={errors?.city?.message}
          value={formValue.city}
        />
        <Select
          formValue={formValue}
          name="state"
          control={control}
          placeholder="State"
          options={STATES}
          errorMessage={errors?.state?.message}
        />
      </div>
      <Select
        formValue={formValue}
        name="fundraisingStage"
        control={control}
        placeholder="Fundraising Stage"
        options={FUNDRAISING_STAGE}
        errorMessage={errors?.fundraisingStage?.message}
      />
      <Input
        type="text"
        placeholder="Industry"
        register={register}
        registerName="industry"
        errorMessage={errors?.industry?.message}
        value={formValue.industry}
      />
      <Select
        formValue={formValue}
        name="yearlyRevenue"
        control={control}
        placeholder="Yearly Revenue"
        options={YEARLY_REVENUE}
        errorMessage={errors?.yearlyRevenue?.message}
      />
      <div className={style.signUp__sep}>
        <div className={style.signUp__checkWrap}>
          <p>How frequently do you sign contracts?</p>
          <div className={style.signUp__check}>
            <div className={style.signUp__row}>
              <div className="">
                <RadioBox
                  label="Once a month"
                  register={register}
                  registerName="frequencyOfSigningContracts"
                  value={FrequencyOfSigningContracts.ONCE_A_MONTH}
                  error={!!errors.frequencyOfSigningContracts}
                >
                  <aside>Once a month</aside>
                </RadioBox>
              </div>
              <div className="">
                <RadioBox
                  label="2-3 times a month"
                  register={register}
                  registerName="frequencyOfSigningContracts"
                  value={FrequencyOfSigningContracts.TWO_THREE_TIMES_A_MONTH}
                  error={!!errors.frequencyOfSigningContracts}
                >
                  <aside>2-3 times a month</aside>
                </RadioBox>
              </div>
            </div>
            <div className={style.signUp__row}>
              <div className="">
                <RadioBox
                  label="3+ times a month"
                  register={register}
                  registerName="frequencyOfSigningContracts"
                  value={FrequencyOfSigningContracts.THREE_PLUS_TIMES_A_MONTH}
                  error={!!errors.frequencyOfSigningContracts}
                >
                  <aside>3+ times a month</aside>
                </RadioBox>
              </div>
              <div className="">
                <RadioBox
                  label="Less than once a month"
                  register={register}
                  registerName="frequencyOfSigningContracts"
                  value={FrequencyOfSigningContracts.LESS_THAN_ONCE_A_MONTH}
                  error={!!errors.frequencyOfSigningContracts}
                >
                  <aside>Less than once a month</aside>
                </RadioBox>
              </div>
            </div>
          </div>
          {errors.frequencyOfSigningContracts && (
            <div className={style.signUp__error}>
              {errors.frequencyOfSigningContracts.message}
            </div>
          )}
        </div>
      </div>
      <div className={style.signUp__sep}>
        <p>
          How many contracts did you sign in the past 3 months (your best
          guess)?
        </p>
        <Input
          type="text"
          placeholder="SAFEs"
          register={register}
          registerName="safesContracts"
          errorMessage={errors?.safesContracts?.message}
          value={formValue.safesContracts}
        />
        <Input
          type="text"
          placeholder="Service Agreements (Buying Software)"
          register={register}
          registerName="serviceAgreementsContracts"
          errorMessage={errors?.serviceAgreementsContracts?.message}
          value={formValue.serviceAgreementsContracts}
        />

        <Input
          type="text"
          placeholder="NDAs"
          register={register}
          registerName="ndasContracts"
          errorMessage={errors?.ndasContracts?.message}
          value={formValue.ndasContracts}
        />
        <Input
          type="text"
          placeholder="Work Contracts (Contractor)"
          register={register}
          registerName="workContracts"
          errorMessage={errors?.workContracts?.message}
          value={formValue.workContracts}
        />

        <Input
          type="text"
          placeholder="Procurement Agreements (Buying Product)"
          register={register}
          registerName="procurementContracts"
          errorMessage={errors?.procurementContracts?.message}
          value={formValue.procurementContracts}
        />
      </div>
      <div className={style.signUp__sep}>
        <div>
          <p>What other types of contracts do you sign?</p>
          <Input
            type="text"
            placeholder="Other Types of Contracts"
            register={register}
            registerName="otherContracts"
            value={formValue.otherContracts}
          />
          <Input
            type="text"
            placeholder="Number of Other Types of Contracts"
            register={register}
            registerName="numberOfOtherContracts"
            errorMessage={errors?.numberOfOtherContracts?.message}
            value={formValue.numberOfOtherContracts}
          />
        </div>
      </div>
      <div className={style.signUp__check}>
        <CheckBox
          label=""
          register={register}
          registerName="agreeToTerms"
          error={!!errors?.agreeToTerms?.message}
        >
          <aside
            className={`${!!errors?.agreeToTerms?.message && style.isError}`}
          >
            {" "}
            I agree with <a>Terms</a> and <a>Privacy</a>
          </aside>
        </CheckBox>
      </div>
    </div>
  );
};

export default Step2;
