import * as yup from "yup";

export const editDocumentSchema = yup.object().shape({
  name: yup
    .string()
    .required("Document name is required")
    .trim(),
    file: yup
    .mixed()
    .test("required", "Please upload a document file", function(value: any) {
      return value && value[0] instanceof File;
    })
    .required("Please upload a document file"),
});
