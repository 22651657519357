import React from "react";
import Input from "../../atoms/Input/Input";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import Button from "../../atoms/Button/Button";
import { StorageEnums } from "../../enums/storageEnums";

interface EditContractProps {
  onSubmit: SubmitHandler<FieldValues>;
  loading: boolean;
}
const EditContract: React.FC<EditContractProps> = ({ onSubmit, loading }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useFormContext();

  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || ""
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Input
          type="text"
          label="Contract Name"
          placeholder="Contract Name"
          register={register}
          registerName="name"
          errorMessage={errors?.name?.message}
        />

        <Button
          type="submit"
          fullWidth={true}
          loading={loading}
          disabled={loading}
          label={"Update Contract Name"}
        />
      </form>
    </div>
  );
};

export default EditContract;
