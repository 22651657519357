import style from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footer__content}>
        <div className={style.footer__content__title}>WEBSITE DISCLAIMER</div>
        <div>
          Briefme, Inc. operates as a provider of self-help services and is not
          an attorney or a law firm. Our service is intended for informational
          purposes only and should not be considered legal advice. Users are
          solely responsible for the use of this tool and should exercise their
          own discretion when relying on its results. We strongly advise
          consulting with a qualified attorney for personalized legal guidance.
          By using these services and engaging with our platform, you agree to
          be bound by the Disclaimers{" "}
          <a href="https://www.briefme.io/terms-of-service" target="_blank">
            {" "}
            Terms of Service{" "}
          </a>
          and{" "}
          <a href="https://www.briefme.io/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
