import { useState, useEffect } from "react";

const isValidTextApp = (text: string) => {
  const regex = /^(?!\s)(?!.*\s$)(?!.{21,}$)\s*\S+(\s+\S+)*\s*$/;
  return regex.test(text);
}

const getCookie = (name: string) => {
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

const AdminGuard = ({ children, ...rest }: any) => {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    const cookieValue = getCookie('authenticatedUser');
    if (cookieValue) {
      setAuthenticated(true);
    } else {
      authenticateUser();
    }
  }, [window.location.pathname]);

  const authenticateUser = () => {
    let username, password;
    while (true) {
        username = prompt('Username:');
        if (!username || !isValidTextApp(username.trim())) {
            alert('Invalid username');
            continue;
        }
        password = prompt('Password:');
        if (!password || !isValidTextApp(password.trim())) {
            alert('Invalid password');
            continue;
        }
        if (username === process.env.REACT_APP_SYSTEM_USERNAME && password === process.env.REACT_APP_SYSTEM_PASSWORD) {
            document.cookie = 'authenticatedUser=true; path=/; max-age=3600';
            setAuthenticated(true);
            break;
        } else {
            alert('Invalid username or password! Please try again.');
        }
    }
};
  return authenticated ? children : null;
};

export default AdminGuard;