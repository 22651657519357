import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  useDocumentOptionsQuery,
  useStandardDocMutation,
} from "./DocumentTemplate.state";
import { Button, Col, Form, Row, Select, Typography, Spin } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { IconAvatar } from "../../../components/components.style";
import {
  ControllerRowItem,
  ControllerRowItemForm,
  DownloadDiv,
  TemplateReferenceBox
} from "../ComparisonForm.style";
import { ComparisonContext } from "../ComparisonProvider";
import { getText } from "../../../utils/textParser";

import Plausible from "plausible-tracker";
const { trackEvent } = Plausible();

const DocumentTemplate = ({setSelectedDocTitle}) => {
  const {
    templateStates: [, setNewValue],
  } = useContext(ComparisonContext);
  const [docId, setDocId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { data = [], isFetching } = useDocumentOptionsQuery();
  const { mutateAsync: getStandardDocUrl, isPending } = useStandardDocMutation({
    setNewValue,
    getText,
  });
  const templates = useMemo(() => {
    return data.map((option) => ({
      value: option.record_id,
      label: option.display_name,
    }));
  }, [data]);

  const onProcessDocument = useCallback(
    async (evt, _docId) => {
      trackEvent('standardDocLoad', { props: { docId: docId } })
      evt && evt.preventDefault();
      setProcessing(true);
      // docId can be read either from the event or from the state
      await getStandardDocUrl(_docId || docId);
      setTimeout(() => {
        // manually set processing to false after 3 seconds
        // since DiffViewer is not broadcasting the end of the process
        setProcessing(false);
      }, 3000);
    },
    [docId, getStandardDocUrl]
  );

  const handleSelectChange = useCallback(
    (value) => {
      const selectedDoc = templates?.find(template => template.value === value);
      setSelectedDocTitle(selectedDoc.label);
      setDocId(value);
      onProcessDocument(null, value);
    },
    [onProcessDocument]
  )

  useEffect(() => {
    let processed = false;
    if (templates.length > 0 && !docId && !processed) {
      setDocId(templates[0].value);
      onProcessDocument(null, templates[0].value);
    }
    return () => {
      processed = true;
    };
  }, [templates, docId, onProcessDocument]);

  const sampleDocs = [
    {
      name: 'Safe: Valuation Cap, no Discount',
      link: 'https://www.ycombinator.com/assets/ycdc/Postmoney%20Safe%20-%20Valuation%20Cap%20Only%20-%20FINAL-f2a64add6d21039ab347ee2e7194141a4239e364ffed54bad0fe9cf623bf1691.docx',
    },
    {
      name: 'Safe: Discount, no Valuation Cap',
      link: 'https://www.ycombinator.com/assets/ycdc/Postmoney%20Safe%20-%20Discount%20Only%20-%20FINAL-b9ecb516615d60c6c4653507442aa2561023004368232b7d6e75edc9629acc99.docx',
    },
    {
      name: 'Safe: MFN, no Valuation Cap, no Discount',
      link: 'https://www.ycombinator.com/assets/ycdc/Postmoney%20Safe%20-%20MFN%20Only%20-%20FINAL-2bc87fa3d2ec5072a60d653aec9a885fb43879781e44341fa720a8e7d1cc42ff.docx'
    },
    {
      name: 'Pro Rata Side Letter',
      link: 'https://www.ycombinator.com/assets/ycdc/Pro%20Rata%20Side%20Letter-d6dd8d827741862b18fba0f658da17fb4e787e5f2dda49584b9caea89bf42302.docx'
    },
  ]

  return (
    <form onSubmit={onProcessDocument}>
      <ControllerRowItem>
        <IconAvatar shape="square">
          <EyeOutlined />
        </IconAvatar>
        <ControllerRowItemForm>
          <Form.Item>
            <Typography.Title level={4}>
              1. Select Y Combinator SAFE Template
            </Typography.Title>
          </Form.Item>
          <TemplateReferenceBox>
            <Typography.Text type="secondary"> YC Template References</Typography.Text>
            <div>
              {sampleDocs.map((doc) => (
                <DownloadDiv href={doc.link} download={doc.name}>
                  <div>{doc.name}</div>
                </DownloadDiv>
              ))}
            </div>
          </TemplateReferenceBox>
          <Row gutter={12}>
            <Col md={18}>
              <Select
                id="dropdown"
                value={docId}
                onChange={handleSelectChange}
                loading={isFetching}
                options={templates}
              />
            </Col>
            <Col md={6}>
              <Spin spinning={isPending || processing}/>
            </Col>
          </Row>
        </ControllerRowItemForm>
      </ControllerRowItem>
    </form>
  );
};

export default DocumentTemplate;
