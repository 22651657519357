import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import Button from "../../../../atoms/Button/Button";
import Input from "../../../../atoms/Input/Input";
import { toast } from "react-toastify";
import style from "./EditDocumet.module.scss";
import uploadIcon from "../../../../assets/images/upload.svg";
import tickIcon from "../../../../assets/images/tick.svg";
import crossIcon from "../../../../assets/images/cross-white.svg";

interface EditDocumentProps {
  onSubmit: SubmitHandler<FieldValues>;
  loading: boolean;
  setFile: any;
  file: File | undefined;
}
const EditDocument: React.FC<EditDocumentProps> = ({
  onSubmit,
  loading,
  setFile,
  file,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useFormContext();
  const [key, setKey] = useState(Date.now());
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const allowedTypes = ["application/pdf"];

      if (allowedTypes.includes(files[0].type)) {
        setFile(files[0]);
      } else {
        toast.error("Please upload a valid document file (PDF).");
      }
    }
  };

  const handleDeleteFile = () => {
    setFile("");
    setKey(Date.now());
  };
  return (
    <div className={style.editDocument}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <label className={style.editDocument__upload}>
          <input
            key={key}
            type="file"
            {...register("file", {
              required: "Please upload a document file (PDF).",
            })}
            onChange={handleFileChange}
            accept=".pdf"
          />

          <img src={uploadIcon} alt="upload icon" />
          <p>
            <span>Drag files here or</span> <strong>browse</strong>
          </p>
          <div className={style.editDocument__error}>
            {errors["file"]?.message && (errors["file"]?.message as string)}
          </div>
        </label>

        {file && (
          <div className={`${style.editDocument__file} flex align-center`}>
            <img src={tickIcon} alt="tick" />
            <p>{file.name}</p>
            <img
              className={style.isDelete}
              onClick={handleDeleteFile}
              src={crossIcon}
              alt="delete"
            />
          </div>
        )}

        <Input
          type="text"
          label="Document Name"
          placeholder="Document Name"
          register={register}
          registerName="name"
          errorMessage={errors?.name?.message}
        />

        <Button
          type="submit"
          fullWidth={true}
          loading={loading}
          disabled={loading}
          label={"Update Document"}
        />
      </form>
    </div>
  );
};

export default EditDocument;
