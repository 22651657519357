const useSyncScroll = () => {
  let isSyncingLeftScroll = false;
  let isSyncingRightScroll = false;
  const leftDiv = document.querySelectorAll(
    '[class*="react-diff-1n7ec1i-line"]'
  )[0];
  const rightDiv = document.querySelectorAll(
    '[class*="react-diff-1n7ec1i-line"]'
  )[1];

  if (leftDiv && rightDiv) {
    leftDiv.addEventListener("scroll", (e) => {
      if (!isSyncingLeftScroll) {
        isSyncingRightScroll = true;
        rightDiv.scrollTop = e.target.scrollTop;
      }
      isSyncingLeftScroll = false;
    });

    rightDiv.addEventListener("scroll", (e) => {
      if (!isSyncingRightScroll) {
        isSyncingLeftScroll = true;
        leftDiv.scrollTop = e.target.scrollTop;
      }
      isSyncingRightScroll = false;
    });
  }
};

export default useSyncScroll;