import { useEffect, useState } from "react";
import { StorageEnums } from "../../enums/storageEnums";
import { CognitoService } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import Sidebar from "../../components/SideBar/Sidebar";
import { SIDEBAR_TABS } from "../../constants/sidebar.constants";
import { Outlet } from "react-router";
import Layout from "../../components/Layout/Layout";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useHideHeader from "../../hooks/useHideHeader";

const UserDashboard = () => {
  const [showNav, setShowNav] = useState(false);
  const service = new CognitoService();
  const storageService = new StorageService();
  useEffect(() => {
    // checkData();
  }, []);

  async function checkData() {
    try {
      const creds = storageService.getKey(StorageEnums.CREDENTIALS);
      const refreshToken = creds.refreshToken.token;
      const email = creds.idToken.payload.email;
      const res = await service.refreshSession(email, refreshToken);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
    } catch (error) {
      console.log(error);
    }
  }
  const removeBorder = useHideHeader();
  return (
    <>
      <Header
        bottomBorder={!removeBorder}
        showNav={showNav}
        setShowNav={setShowNav}
      />
      <Layout
        sidebar={
          <Sidebar
            SidebarTabData={SIDEBAR_TABS}
            showNav={showNav}
            setShowNav={setShowNav}
          />
        }
        content={<Outlet />}
      />
      <Footer />
    </>
  );
};
export default UserDashboard;
